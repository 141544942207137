import html2pdf from 'html2pdf.js';

// Function to determine the track based on age
const determineTrack = (age) => {
  if (age >= 6 && age <= 8) return 'Track 1 (6-8)';
  if (age >= 9 && age <= 12) return 'Track 2 (9-12)';
  return 'Track 3 (13-16+)';
};

const generatePDF = async (registrations) => {
  try {
    const content = `
      <html>
        <head>
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');
            body {
              font-family: 'Poppins', sans-serif;
              background-color: #FFF7ED;
              color: #333;
              line-height: 1.4;
              padding: 10px;
            }
            .header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              background-color: #FFFFFF;
              padding: 10px;
              border-radius: 8px;
              margin-bottom: 15px;
              border: 2px solid #F97316;
            }
            .logo-container {
              background-color: #FFFFFF;
              padding: 5px;
              border-radius: 5px;
            }
            .logo {
              height: 40px;
              display: block;
            }
            .header-text {
              text-align: right;
              color: #F97316;
            }
            h1 { 
              margin: 0; 
              font-size: 24px; 
              font-weight: 600;
            }
            .subtitle {
              font-size: 14px;
              opacity: 0.8;
            }
            table {
              width: 100%;
              border-collapse: separate;
              border-spacing: 0;
              margin-bottom: 15px;
            }
            th, td {
              padding: 10px;
              text-align: left;
              font-size: 12px;
              border-bottom: 1px solid #F97316;
            }
            th {
              background-color: #F97316;
              color: white;
              font-weight: 600;
              text-transform: uppercase;
              letter-spacing: 0.5px;
            }
            tr:nth-child(even) {
              background-color: #FFF7ED;
            }
            tr:nth-child(odd) {
              background-color: #FFFFFF;
            }
            .status-badge {
              display: inline-block;
              padding: 3px 8px;
              border-radius: 12px;
              font-size: 10px;
              font-weight: bold;
              text-transform: uppercase;
            }
            .status-offline { background-color: #FEE2E2; color: #EF4444; }
            .status-online { background-color: #D1FAE5; color: #10B981; }
            .status-hybrid { background-color: #F3E8FF; color: #9333EA; }
            .footer {
              text-align: center;
              font-size: 12px;
              color: #666;
              font-style: italic;
              padding: 10px;
              border-top: 1px solid #F97316;
              margin-top: 15px;
            }
          </style>
        </head>
        <body>
          <div class="header">
            <div class="logo-container">
              <img src="/images/nextgen-logo.png" alt="NextGen Programmer Logo" class="logo">
            </div>
            <div class="header-text">
              <h1>Registration Report</h1>
              <div class="subtitle">${new Date().toLocaleDateString()}</div>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>Student Name</th>
                <th>Age</th>
                <th>School</th>
                <th>Parent's Name</th>
                <th>Contact</th>
                <th>Class Type</th>
                <th>Track</th>
                <th>Location</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              ${registrations.map(reg => `
                <tr>
                  <td>${reg.student_name}</td>
                  <td>${reg.age}</td>
                  <td>${reg.school_name}</td>
                  <td>${reg.parents_name}</td>
                  <td>${reg.parents_mobile}</td>
                  <td>
                    <span class="status-badge ${reg.preferred_class_type.toLowerCase() === 'offline' ? 'status-offline' : reg.preferred_class_type.toLowerCase() === 'online' ? 'status-online' : 'status-hybrid'}">
                      ${reg.preferred_class_type}
                    </span>
                  </td>
                  <td>${determineTrack(reg.age)}</td>
                  <td>${reg.location}</td>
                  <td>${new Date(reg.registration_date).toLocaleDateString()}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
          <div class="footer">
            NextGen Programmer - Empowering the next generation of programmers
          </div>
        </body>
      </html>
    `;

    const opt = {
      margin: 10,
      filename: 'nextgen_registrations.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, logging: true, useCORS: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' }
    };

    await html2pdf().from(content).set(opt).save();
    console.log('PDF generated successfully');
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw new Error('Failed to generate PDF. Please try again. Error: ' + error.message);
  }
};

export { generatePDF };
