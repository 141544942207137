// TrackDistributionChart.js
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';

const TrackDistributionChart = ({ filteredRegistrations, determineTrack }) => {
  const trackData = [
    { name: 'Track 1 (6-8)', value: filteredRegistrations.filter((r) => determineTrack(r.age) === 'Track 1 (6-8)').length },
    { name: 'Track 2 (9-12)', value: filteredRegistrations.filter((r) => determineTrack(r.age) === 'Track 2 (9-12)').length },
    { name: 'Track 3 (13-16+)', value: filteredRegistrations.filter((r) => determineTrack(r.age) === 'Track 3 (13-16+)').length },
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

  return (
    <div className="bg-white p-6 rounded-2xl shadow-lg">
      <h2 className="text-2xl font-bold mb-4 text-orange-600">Track Distribution</h2>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={trackData}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="value" fill="#8884d8">
            {trackData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TrackDistributionChart;
