import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import { User, Lock, Flame } from 'lucide-react';

const AdminLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}/login`, { username, password });
            localStorage.setItem('token', response.data.token);
            navigate('/admin/dashboard');
        } catch (error) {
            setError('Invalid credentials');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-orange-400 via-red-500 to-orange-600 overflow-hidden relative">
            <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                className="bg-white bg-opacity-20 backdrop-blur-lg p-8 rounded-3xl shadow-2xl w-full max-w-md relative z-10"
            >
                <motion.div
                    initial={{ y: -50 }}
                    animate={{ y: 0 }}
                    transition={{ delay: 0.2, type: "spring", stiffness: 120 }}
                >
                    <Flame className="w-16 h-16 mx-auto mb-6 text-orange-200" />
                    <h2 className="text-4xl font-bold mb-6 text-center text-white">NextGen Admin</h2>
                </motion.div>
                {error && (
                    <motion.p
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="text-orange-100 text-center mb-4 bg-red-600 bg-opacity-25 py-2 rounded-lg"
                    >
                        {error}
                    </motion.p>
                )}
                <form onSubmit={handleLogin} className="space-y-6">
                    <motion.div
                        className="relative"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        <User className="absolute top-3 left-3 text-orange-200" />
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Username"
                            className="w-full pl-10 pr-4 py-3 bg-orange-100 bg-opacity-20 border border-orange-200 border-opacity-50 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-300 text-white placeholder-orange-200"
                            required
                        />
                    </motion.div>
                    <motion.div
                        className="relative"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        <Lock className="absolute top-3 left-3 text-orange-200" />
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            className="w-full pl-10 pr-4 py-3 bg-orange-100 bg-opacity-20 border border-orange-200 border-opacity-50 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-300 text-white placeholder-orange-200"
                            required
                        />
                    </motion.div>
                    <motion.button
                        type="submit"
                        className="w-full bg-gradient-to-r from-orange-400 to-red-500 text-white py-3 rounded-lg font-semibold text-lg transition-all duration-300 hover:from-orange-500 hover:to-red-600"
                        whileHover={{ scale: 1.05, boxShadow: "0px 0px 8px rgb(255,160,0)" }}
                        whileTap={{ scale: 0.95 }}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <motion.div
                                className="inline-block"
                                animate={{ rotate: 360 }}
                                transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                            >
                                🔥
                            </motion.div>
                        ) : (
                            'Access Portal'
                        )}
                    </motion.button>
                </form>
            </motion.div>
            {[...Array(5)].map((_, index) => (
                <motion.div
                    key={index}
                    className="absolute rounded-full bg-orange-300 bg-opacity-20"
                    style={{
                        width: Math.random() * 100 + 50,
                        height: Math.random() * 100 + 50,
                        top: `${Math.random() * 100}%`,
                        left: `${Math.random() * 100}%`,
                    }}
                    animate={{
                        y: [0, Math.random() * 100 - 50],
                        x: [0, Math.random() * 100 - 50],
                        scale: [1, Math.random() + 0.5],
                    }}
                    transition={{
                        duration: Math.random() * 10 + 10,
                        repeat: Infinity,
                        repeatType: "reverse",
                    }}
                />
            ))}
        </div>
    );
};

export default AdminLogin;