// RegistrationForm.js
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Laptop, Wifi, User, Calendar, School, Users, Phone, MapPin, BookOpen, AlertCircle } from 'lucide-react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function RegistrationForm() {
    const [formData, setFormData] = useState({
        student_name: '',
        age: '',
        school_name: '',
        parents_name: '',
        parents_mobile: '',
        preferred_class_type: '',
        has_computer: false,
        has_wifi: false,
        location: '',
    });

    const [submitError, setSubmitError] = useState('');
    const [validationErrors, setValidationErrors] = useState({});
    const [language, setLanguage] = useState('bn');
    const [showAgeWarning, setShowAgeWarning] = useState(false);

    const navigate = useNavigate();

    const content = {
        en: {
            title: "Book Free Class",
            fields: {
                student_name: "Your Child's Name",
                age: "Your Child's Age",
                school_name: "Your Child's School/College Name",
                parents_name: "Parent's Name",
                parents_mobile: "Parent's Mobile Number",
                preferred_class_type: "Preferred Class Type",
                class_types: {
                    OFFLINE: "Offline Class (In Classroom)",
                    ONLINE: "Online Class (Virtual Classroom)"
                },
                has_computer: "Has Computer",
                has_wifi: "Has Internet Connection",
                location: "Address"
            },
            submit: "Complete Registration",
            error: "There was an error with your registration. Please try again.",
            ageWarning: "Notice: For children aged 6-12 years, we only offer offline classes at our Feni campus. Please register for offline classes only.",
            validation: {
                required: "This field is required",
                invalidPhone: "Invalid phone number format, must be 11 digits",
                invalidName: "Name must be at least 2 characters",
                invalidAge: "Age must be a positive number",
                invalidClassType: "Please select a class type",
                invalidComputer: "For online class, you must have a computer",
                invalidWifi: "For online class, you must have an internet connection",
                invalidAddress: "Address must be at least 5 characters",
                ageRestriction: "For ages 6-12, only offline classes are available at our Feni campus. Online classes are available for ages 13 and above."
            },
            languageToggle: 'বাংলা'
        },
        bn: {
            title: "ফ্রি ক্লাস বুক করুন",
            fields: {
                student_name: "আপনার সন্তানের নাম",
                age: "আপনার সন্তানের বয়স",
                school_name: "আপনার সন্তানের স্কুল/কলেজ এর  নাম",
                parents_name: "অভিভাবকের নাম",
                parents_mobile: "অভিভাবকের মোবাইল নম্বর",
                preferred_class_type: "পছন্দের ক্লাস ধরন",
                class_types: {
                    OFFLINE: "অফলাইন ক্লাস (সরাসরি ক্লাসরুমে)",
                    ONLINE: "অনলাইন ক্লাস (ভার্চুয়াল ক্লাসরুমে)"
                },
                has_computer: "কম্পিউটার আছে",
                has_wifi: "ইন্টারনেট সংযোগ আছে",
                location: "ঠিকানা"
            },
            submit: "রেজিস্ট্রেশন সম্পন্ন করুন",
            error: "আপনার রেজিস্ট্রেশনে একটি ত্রুটি ছিল। অনুগ্রহ করে আবার চেষ্টা করুন।",
            ageWarning: "বিজ্ঞপ্তি: ৬-১২ বছর বয়সী শিক্ষার্থীদের জন্য আমরা শুধুমাত্র ফেনী ক্যাম্পাসে অফলাইন ক্লাস প্রদান করি। অনুগ্রহ করে অফলাইন ক্লাসের জন্য রেজিস্ট্রেশন করুন।",
            validation: {
                required: "এই ক্ষেত্রটি আবশ্যক",
                invalidPhone: "ফোন নম্বর 11 সংখ্যার হতে হবে",
                invalidName: "নাম অবশ্যই কমপক্ষে ২ অক্ষরের হতে হবে",
                invalidAge: "বয়স অবশ্যই একটি ধনাত্মক সংখ্যা হতে হবে",
                invalidClassType: "অনুগ্রহ করে একটি ক্লাস ধরন নির্বাচন করুন",
                invalidComputer: "অনলাইন ক্লাসের জন্য আপনার কম্পিউটার থাকতে হবে",
                invalidWifi: "অনলাইন ক্লাসের জন্য ইন্টারনেট সংযোগ থাকতে হবে",
                invalidAddress: "ঠিকানা অবশ্যই কমপক্ষে ৫ অক্ষরের হতে হবে",
                ageRestriction: "৬-১২ বছর বয়সীদের জন্য শুধুমাত্র ফেনী ক্যাম্পাসে অফলাইন ক্লাস উপলব্ধ। অনলাইন ক্লাস ১৩+ বছর বয়সীদের জন্য উপলব্ধ।"
            },
            languageToggle: 'English'
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;

        setFormData(prevState => {
            const newState = {
                ...prevState,
                [name]: newValue
            };

            // Handle age changes
            if (name === 'age') {
                const age = parseInt(value);
                // Show warning for ages 6-12
                setShowAgeWarning(age >= 6 && age <= 12);
                // Reset class type if age is 6-12 and online was selected
                if (age >= 6 && age <= 12 && newState.preferred_class_type === 'ONLINE') {
                    newState.preferred_class_type = '';
                }
            }

            return newState;
        });

        setValidationErrors(prevState => ({
            ...prevState,
            [name]: ''
        }));
    };

    const validateForm = () => {
        let errors = {};
        const phoneRegex = /^\d{11}$/;
        const age = parseInt(formData.age);

        if (!formData.student_name || formData.student_name.length < 2) {
            errors.student_name = content[language].validation.invalidName;
        }
        if (!formData.age || isNaN(formData.age) || formData.age <= 0) {
            errors.age = content[language].validation.invalidAge;
        }
        if (!formData.school_name || formData.school_name.length < 2) {
            errors.school_name = content[language].validation.required;
        }
        if (!formData.parents_name || formData.parents_name.length < 2) {
            errors.parents_name = content[language].validation.required;
        }
        if (!formData.parents_mobile || !phoneRegex.test(formData.parents_mobile)) {
            errors.parents_mobile = content[language].validation.invalidPhone;
        }
        if (!formData.preferred_class_type) {
            errors.preferred_class_type = content[language].validation.invalidClassType;
        } else {
            // Age restriction validation
            if (age >= 6 && age <= 12 && formData.preferred_class_type === 'ONLINE') {
                errors.preferred_class_type = content[language].validation.ageRestriction;
            }
            
            // Online class requirements validation
            if (formData.preferred_class_type === 'ONLINE') {
                if (!formData.has_computer) {
                    errors.has_computer = content[language].validation.invalidComputer;
                }
                if (!formData.has_wifi) {
                    errors.has_wifi = content[language].validation.invalidWifi;
                }
            }
        }
        if (!formData.location || formData.location.length < 5) {
            errors.location = content[language].validation.invalidAddress;
        }

        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            return;
        }
    
        try {
            const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';
            const response = await axios.post(`${apiUrl}/trial-class/register`, formData);
            console.log('Form submitted:', response.data);
            setSubmitError('');
            navigate('/registration/success');
        } catch (error) {
            console.error('Error submitting form:', error.response ? error.response.data : error.message);
            setSubmitError(content[language].error);
        }
    };

    const toggleLanguage = () => setLanguage(language === 'en' ? 'bn' : 'en');

    const isOnlineDisabled = (age) => {
        age = parseInt(age);
        return age >= 6 && age <= 12;
    };

    const getAvailableClassTypes = () => {
        const age = parseInt(formData.age);
        if (isOnlineDisabled(age)) {
            return Object.entries(content[language].fields.class_types)
                .filter(([value]) => value === 'OFFLINE')
                .map(([value, label]) => ({ value, label }));
        }
        return Object.entries(content[language].fields.class_types)
            .map(([value, label]) => ({ value, label }));
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="bg-white p-4 sm:p-8 rounded-2xl shadow-2xl w-full max-w-4xl mx-auto space-y-6"
            >
                <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
                    <h1 className="text-3xl sm:text-4xl font-bold text-orange-600 mb-4 sm:mb-0">{content[language].title}</h1>
                    <button
                        onClick={toggleLanguage}
                        className="px-4 py-2 bg-orange-500 text-white rounded-full hover:bg-orange-600 transition duration-300"
                    >
                        {content[language].languageToggle}
                    </button>
                </div>

                {showAgeWarning && (
                    <div className="flex items-center gap-3 p-4 mb-4 text-blue-800 border border-blue-300 rounded-lg bg-blue-50">
                        <AlertCircle className="h-5 w-5" />
                        <p>{content[language].ageWarning}</p>
                    </div>
                )}

                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                        <div className="col-span-3">
                            <Input
                                label={content[language].fields.student_name}
                                name="student_name"
                                value={formData.student_name}
                                onChange={handleChange}
                                error={validationErrors.student_name}
                                icon={<User className="w-5 h-5 text-gray-400" />}
                            />
                        </div>
                        <div className="col-span-1">
                            <Input
                                label={content[language].fields.age}
                                name="age"
                                value={formData.age}
                                onChange={handleChange}
                                type="number"
                                error={validationErrors.age}
                                icon={<Calendar className="w-5 h-5 text-gray-400" />}
                            />
                        </div>
                    </div>
                    <Input
                        label={content[language].fields.school_name}
                        name="school_name"
                        value={formData.school_name}
                        onChange={handleChange}
                        error={validationErrors.school_name}
                        icon={<School className="w-5 h-5 text-gray-400" />}
                    />
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="col-span-2">
                            <Input
                                label={content[language].fields.parents_name}
                                name="parents_name"
                                value={formData.parents_name}
                                onChange={handleChange}
                                error={validationErrors.parents_name}
                                icon={<Users className="w-5 h-5 text-gray-400" />}
                            />
                        </div>
                        <div className="col-span-1">
                            <Input
                                label={content[language].fields.parents_mobile}
                                name="parents_mobile"
                                value={formData.parents_mobile}
                                onChange={handleChange}
                                error={validationErrors.parents_mobile}
                                icon={<Phone className="w-5 h-5 text-gray-400" />}
                            />
                        </div>
                    </div>
                    <Select
                        label={content[language].fields.preferred_class_type}
                        name="preferred_class_type"
                        value={formData.preferred_class_type}
                        onChange={handleChange}
                        error={validationErrors.preferred_class_type}
                        options={getAvailableClassTypes()}
                        icon={<BookOpen className="w-5 h-5 text-gray-400" />}
                    />
                    <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6">
                        <Checkbox label={content[language].fields.has_computer} name="has_computer" checked={formData.has_computer} onChange={handleChange} error={validationErrors.has_computer} icon={<Laptop className="w-5 h-5" />} />
                        <Checkbox label={content[language].fields.has_wifi} name="has_wifi" checked={formData.has_wifi} onChange={handleChange} error={validationErrors.has_wifi} icon={<Wifi className="w-5 h-5" />} />
                    </div>
                    <Input
                        label={content[language].fields.location}
                        name="location"
                        value={formData.location}
                        onChange={handleChange}
                        error={validationErrors.location}
                        icon={<MapPin className="w-5 h-5 text-gray-400" />}
                    />

                    {submitError && <p className="text-red-500 text-center">{submitError}</p>}

                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        type="submit"
                        className="w-full px-6 py-3 bg-orange-500 text-white rounded-lg font-semibold hover:bg-orange-600 transition duration-300 text-lg"
                    >
                        {content[language].submit}
                    </motion.button>
                </form>
            </motion.div>
        </div>
    );
}

const Input = ({ label, icon, error, ...props }) => (
    <div className="flex-1">
        <label className="block text-gray-700 mb-1 text-lg">{label}</label>
        <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                {icon}
            </div>
            <input
                {...props}
                className={`w-full pl-10 pr-4 py-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 text-gray-700`}
            />
        </div>
        {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
);

const Select = ({ label, options, icon, error, ...props }) => (
    <div>
        <label className="block text-gray-700 mb-1 text-lg">{label}</label>
        <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                {icon}
            </div>
            <select
                {...props}
                className={`w-full pl-10 pr-4 py-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 text-gray-700 appearance-none`}
            >
                <option value="">-- Select an option --</option>
                {options.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                ))}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                <svg className="w-4 h-4 fill-current text-gray-400" viewBox="0 0 20 20">
                    <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" fillRule="evenodd"></path>
                </svg>
            </div>
        </div>
        {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
);

const Checkbox = ({ label, icon, error, ...props }) => (
    <label className="flex items-center space-x-3 text-gray-700">
        <input type="checkbox" {...props} className={`form-checkbox h-5 w-5 text-orange-500 rounded focus:ring-orange-500 focus:ring-offset-0 ${error ? 'border-red-500' : ''}`} />
        {icon}
        <span>{label}</span>
        {error && <p className="text-red-500 text-sm ml-2">{error}</p>}
    </label>
);

export default RegistrationForm;