import React from 'react';
import { motion } from 'framer-motion';
import { Briefcase, Users, Clock, Book, Zap, Award, Cpu, Globe, Code } from 'lucide-react';

const CareerSection = ({ language }) => {
    const content = {
        title: language === 'bn' ? 'আমাদের টিমে যোগ দিন!' : 'Join Our Team!',
        description: language === 'bn' 
            ? 'আপনি কি কোডিং শেখানোর জন্য আগ্রহী? নেক্সটজেন প্রোগ্রামারের সাথে পার্ট-টাইম প্রোগ্রামিং ইন্সট্রাক্টর হিসাবে যোগ দিন!'
            : 'Do you like teaching coding? Join NextGen Programmer as a Part-Time Programming Instructor!',
        whyJoinTitle: language === 'bn' ? 'কেন নেক্সটজেন প্রোগ্রামারের সাথে যোগ দেবেন?' : 'Why Join NextGen Programmer?',
        benefits: [
            { icon: Award, text: language === 'bn' ? 'প্রতি ক্লাস 120-250 টাকা পারিশ্রমিক' : 'Pay: 120-250 BDT per class' },
            { icon: Clock, text: language === 'bn' ? 'ফ্লেক্সিবল ক্লাস সময়সূচি' : 'Flexible schedule' },
            { icon: Zap, text: language === 'bn' ? 'আনন্দদায়ক শিক্ষার পরিবেশ' : 'Fun learning environment' },
            { icon: Book, text: language === 'bn' ? 'প্রফেশনাল উন্নতির জন্য সহায়তা ও প্রশিক্ষণ' : 'Training for growth' }
        ],
        schedule: {
            title: language === 'bn' ? 'ক্লাসের সময়সূচি' : 'Class Schedule',
            offline: language === 'bn' ? 'অফলাইন: ৯am – ১২pm বা ২pm – ৫pm' : 'Offline: 9am – 12pm or 2pm – 5pm',
            online: language === 'bn' ? 'অনলাইন: ৭pm – ৯pm' : 'Online: 7pm – 9pm'
        },
        responsibilities: {
            title: language === 'bn' ? 'দায়িত্বসমূহ' : 'Your Tasks',
            items: [
                language === 'bn' ? 'বাচ্চাদের কোডিং শেখানো' : 'Teach coding to kids',
                language === 'bn' ? 'শিক্ষার্থীদের অগ্রগতি মূল্যায়ন করা' : 'Check student progress',
                language === 'bn' ? 'পিতা-মাতার সাথে নিয়মিত যোগাযোগ' : 'Talk to parents regularly'
            ]
        },
        requirements: {
            title: language === 'bn' ? 'যোগ্যতা' : 'What You Need',
            items: [
                language === 'bn' ? 'যে কোনো বিষয়ে বর্তমানে স্নাতক শিক্ষার্থী (CSE বা IT পড়লে অতিরিক্ত সুবিধা)' : 'Currently a student in any field of study (Honours, but studying CSE or IT is a plus)',
                language === 'bn' ? 'বাংলা ও ইংরেজিতে দক্ষতা' : 'Good at Bangla and English',
                language === 'bn' ? 'Scratch, Python, HTML, CSS, JavaScript সম্পর্কে ধারণা' : 'Know Scratch, Python, HTML, CSS, and JavaScript'
            ]
        },
        cta: language === 'bn' ? 'এখনই আবেদন করুন' : 'Apply Now',
        formLink: "https://forms.gle/cptp53MjHWZW9zQq5"
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1
        }
    };

    return (
        <section className="bg-orange-50 py-20" id="careers">
            <div className="container mx-auto px-4">
                <motion.h2
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="text-4xl md:text-5xl font-bold text-center text-orange-600 mb-8"
                >
                    {content.title}
                </motion.h2>
                <motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2, duration: 0.5 }}
                    className="text-xl md:text-2xl text-center text-gray-700 mb-12"
                >
                    {content.description}
                </motion.p>

                <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12"
                >
                    <motion.div variants={itemVariants} className="bg-white rounded-lg shadow-md p-6 space-y-4">
                        <h3 className="text-2xl font-semibold text-orange-600">{content.whyJoinTitle}</h3>
                        <ul className="space-y-3">
                            {content.benefits.map((benefit, index) => (
                                <motion.li key={index} className="flex items-center space-x-3" variants={itemVariants}>
                                    <benefit.icon className="text-orange-500" size={24} />
                                    <span className="text-gray-700">{benefit.text}</span>
                                </motion.li>
                            ))}
                        </ul>
                    </motion.div>

                    <motion.div variants={itemVariants} className="bg-white rounded-lg shadow-md p-6 space-y-4">
                        <h3 className="text-2xl font-semibold text-orange-600">{content.schedule.title}</h3>
                        <div className="space-y-3">
                            <motion.div variants={itemVariants} className="flex items-center space-x-3">
                                <Users className="text-orange-500" size={24} />
                                <span className="text-gray-700">{content.schedule.offline}</span>
                            </motion.div>
                            <motion.div variants={itemVariants} className="flex items-center space-x-3">
                                <Globe className="text-orange-500" size={24} />
                                <span className="text-gray-700">{content.schedule.online}</span>
                            </motion.div>
                        </div>
                    </motion.div>
                </motion.div>

                <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12"
                >
                    <motion.div variants={itemVariants} className="bg-white rounded-lg shadow-md p-6 space-y-4">
                        <h3 className="text-2xl font-semibold text-orange-600">{content.responsibilities.title}</h3>
                        <ul className="space-y-3">
                            {content.responsibilities.items.map((item, index) => (
                                <motion.li key={index} className="flex items-center space-x-3" variants={itemVariants}>
                                    <Code className="text-orange-500" size={24} />
                                    <span className="text-gray-700">{item}</span>
                                </motion.li>
                            ))}
                        </ul>
                    </motion.div>

                    <motion.div variants={itemVariants} className="bg-white rounded-lg shadow-md p-6 space-y-4">
                        <h3 className="text-2xl font-semibold text-orange-600">{content.requirements.title}</h3>
                        <ul className="space-y-3">
                            {content.requirements.items.map((item, index) => (
                                <motion.li key={index} className="flex items-center space-x-3" variants={itemVariants}>
                                    <Cpu className="text-orange-500" size={24} />
                                    <span className="text-gray-700">{item}</span>
                                </motion.li>
                            ))}
                        </ul>
                    </motion.div>
                </motion.div>

                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.5, duration: 0.5 }}
                    className="text-center"
                >
                    <a
                        href={content.formLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-block bg-orange-500 text-white px-8 py-3 rounded-full text-xl font-semibold hover:bg-orange-600 transition duration-300 shadow-md"
                    >
                        {content.cta}
                    </a>
                </motion.div>
            </div>
        </section>
    );
};

export default CareerSection;