import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import {
  Code,
  Brain,
  Rocket,
  ChevronRight,
  Clock,
  Zap,
  Star,
  Cpu,
  Globe,
  Shield,
} from 'lucide-react';

const Programs = ({ language = 'bn' }) => {
  const content = {
    tracks: {
      title:
        language === 'bn'
          ? 'আমাদের ইনোভেটিভ লার্নিং মডিউল'
          : 'Our Innovative Learning Modules',
      subtitle:
        language === 'bn'
          ? 'বর্তমান এবং ভবিষ্যতের প্রযুক্তি শেখার জন্য প্রস্তুত হন'
          : 'Get Ready for Present and Future Technologies',
          items: [
            {
              title:
                language === 'bn'
                  ? 'ফাউন্ডেশন অব প্রোগ্রামিং'
                  : 'Foundation of Programming',
              age: language === 'bn' ? '৬-৮ বছর' : '6-8 years',
              icon: <Rocket />,
              courses:
                language === 'bn'
                  ? ['কম্পিউটার বেসিক', 'Scratch Jr.', 'Scratch Advance', 'ওয়েব ডিজাইন']
                  : ['Computer Basics', 'Scratch Jr.', 'Scratch Advance', 'Web Design'],
              duration: language === 'bn' ? '১২ মাস' : '12 months',
              monthlyFee: language === 'bn' ? '১৪৯৯/- টাকা' : '1499/- BDT',
              quarterlyFee: language === 'bn' ? '৪৪৯৭/- টাকা' : '4497/- BDT',
              isFutureModule: false,
            },
            {
              title:
                language === 'bn'
                  ? 'ইন্টারমেডিয়েট প্রোগ্রামিং এন্ড ওয়েব ডেভেলপমেন্ট'
                  : 'Intermediate Programming and Web Development',
              age: language === 'bn' ? '৯-১২ বছর' : '9-12 years',
              icon: <Code />,
              courses:
                language === 'bn'
                  ? [
                      'কম্পিউটার বেসিক',
                      'Scratch Advanced',
                      'মোবাইল অ্যাপ ডেভেলপমেন্ট',
                      'ওয়েবসাইট বিল্ডিং বেসিক',
                    ]
                  : [
                      'Computer Basics',
                      'Scratch Advanced',
                      'Mobile App Development',
                      'Website Building Basics',
                    ],
              duration: language === 'bn' ? '১২ মাস' : '12 months',
              monthlyFee: language === 'bn' ? '১৪৯৯/- টাকা' : '1499/- BDT',
              quarterlyFee: language === 'bn' ? '৪৪৯৭/- টাকা' : '4497/- BDT',
              isFutureModule: false,
            },
            {
              title:
                language === 'bn'
                  ? 'এডভান্স প্রোগ্রামিং এন্ড ওয়েব টেকনোলজি'
                  : 'Advanced Programming and Web Technology',
              age: language === 'bn' ? '১৩-১৬+ বছর' : '13-16+ years',
              icon: <Brain />,
              courses:
                language === 'bn'
                  ? [
                      'কোর প্রোগ্রামিং ফান্ডামেন্টালস',
                      'অ্যাডভান্সড ওয়েব ডিজাইন',
                      'পাইথন ওয়েব ডেভেলপমেন্ট',
                    ]
                  : [
                      'Core Programming Fundamentals',
                      'Advanced Web Design',
                      'Python Web Development',
                    ],
              duration: language === 'bn' ? '১২ মাস' : '12 months',
              monthlyFee: language === 'bn' ? '১৪৯৯/- টাকা' : '1499/- BDT',
              quarterlyFee: language === 'bn' ? '৪৪৯৭/- টাকা' : '4497/- BDT',
              isFutureModule: false,
            },
            {
              title:
                language === 'bn'
                  ? 'আর্টিফিশিয়াল ইন্টেলিজেন্স এবং মেশিন লার্নিং'
                  : 'Artificial Intelligence and Machine Learning',
              age: language === 'bn' ? '১৪-১৮ বছর' : '14-18 years',
              icon: <Cpu />,
              courses:
                language === 'bn'
                  ? ['AI ফান্ডামেন্টালস', 'পাইথন ফর মেশিন লার্নিং', 'ডীপ লার্নিং বেসিকস']
                  : ['AI Fundamentals', 'Python for Machine Learning', 'Deep Learning Basics'],
              duration: language === 'bn' ? '১৮ মাস' : '18 months',
              monthlyFee: language === 'bn' ? '১৯৯৯/- টাকা' : '1999/- BDT',
              quarterlyFee: language === 'bn' ? '৫৯৯৭/- টাকা' : '5997/- BDT',
              isFutureModule: true,
            },
            {
              title:
                language === 'bn'
                  ? 'ব্লকচেইন এবং ক্রিপ্টোকারেন্সি'
                  : 'Blockchain and Cryptocurrency',
              age: language === 'bn' ? '১৬+ বছর' : '16+ years',
              icon: <Globe />,
              courses:
                language === 'bn'
                  ? ['ব্লকচেইন টেকনোলজি', 'স্মার্ট কন্ট্র্যাক্টস', 'ক্রিপ্টো ইকোনমিক্স']
                  : ['Blockchain Technology', 'Smart Contracts', 'Crypto Economics'],
              duration: language === 'bn' ? '১৫ মাস' : '15 months',
              monthlyFee: language === 'bn' ? '২১৯৯/- টাকা' : '2199/- BDT',
              quarterlyFee: language === 'bn' ? '৬৫৯৭/- টাকা' : '6597/- BDT',
              isFutureModule: true,
            },
            {
              title:
                language === 'bn'
                  ? 'সাইবার সিকিউরিটি এবং এথিক্যাল হ্যাকিং'
                  : 'Cybersecurity and Ethical Hacking',
              age: language === 'bn' ? '১৬+ বছর' : '16+ years',
              icon: <Shield />,
              courses:
                language === 'bn'
                  ? ['নেটওয়ার্ক সিকিউরিটি', 'পেনেট্রেশন টেস্টিং', 'ক্রিপ্টোগ্রাফি']
                  : ['Network Security', 'Penetration Testing', 'Cryptography'],
              duration: language === 'bn' ? '১৮ মাস' : '18 months',
              monthlyFee: language === 'bn' ? '২২৯৯/- টাকা' : '2299/- BDT',
              quarterlyFee: language === 'bn' ? '৬৮৯৭/- টাকা' : '6897/- BDT',
              isFutureModule: true,
            },
          ],
    },
  };

  const [stars, setStars] = useState([]);
  const [hoveredTrack, setHoveredTrack] = useState(null);

  useEffect(() => {
    const generatedStars = [...Array(200)].map(() => ({
      size: Math.random() * 2 + 1,
      x: Math.random() * window.innerWidth,
      y: Math.random() * window.innerHeight,
      opacity: Math.random() * 0.7 + 0.3,
    }));
    setStars(generatedStars);
  }, []);

  const planetVariants = {
    hover: {
      scale: 1.1,
      rotate: 360,
      transition: { duration: 0.8 },
    },
  };

  return (
    <div className="min-h-screen py-12 bg-gradient-to-br from-orange-50 via-orange-100 to-orange-200 overflow-hidden">
      <div className="relative">
        {/* Animated stars */}
        {stars.map((star, i) => (
          <motion.div
            key={i}
            className="absolute rounded-full bg-white"
            style={{
              width: star.size,
              height: star.size,
              left: star.x,
              top: star.y,
              opacity: star.opacity,
            }}
            animate={{
              opacity: [star.opacity, star.opacity * 0.5, star.opacity],
              scale: [1, 1.2, 1],
            }}
            transition={{
              duration: Math.random() * 3 + 2,
              repeat: Infinity,
              ease: 'easeInOut',
            }}
          />
        ))}

        {/* Floating planets */}
        <motion.div
          className="absolute top-1/4 left-10 w-16+ h-16+ sm:w-20 sm:h-20 md:w-24 md:h-24 lg:w-28 lg:h-28 rounded-full bg-gradient-to-br from-orange-300 to-orange-500"
          animate={{
            y: [0, 25, 0],
            rotate: [0, 360, 0],
          }}
          transition={{
            duration: 12,
            repeat: Infinity,
            ease: 'linear',
          }}
          variants={planetVariants}
          whileHover="hover"
        />
        <motion.div
          className="absolute bottom-1/4 right-10 w-20 h-20 sm:w-28 sm:h-28 md:w-32 md:h-32 lg:w-40 lg:h-40 rounded-full bg-gradient-to-tl from-orange-400 to-orange-200"
          animate={{
            y: [0, -35, 0],
            rotate: [0, -360, 0],
          }}
          transition={{
            duration: 18,
            repeat: Infinity,
            ease: 'linear',
          }}
          variants={planetVariants}
          whileHover="hover"
        />

        {/* Shooting star */}
        <motion.div
          className="absolute w-1 h-1 sm:w-1.5 sm:h-1.5 md:w-2 md:h-2 bg-white rounded-full"
          style={{
            top: '5%',
            left: '100%',
          }}
          animate={{
            x: [0, '-100vw'],
            opacity: [0, 1, 0],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            repeatDelay: 8,
            ease: 'easeInOut',
          }}
        />

        <div className="container mx-auto px-4 py-12 relative z-10">
          <motion.h1
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-extrabold text-center mb-4 text-orange-600"
          >
            {content.tracks.title}
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.3 }}
            className="text-xl sm:text-2xl md:text-3xl text-center mb-16+ text-orange-500"
          >
            {content.tracks.subtitle}
          </motion.p>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10">
            {content.tracks.items.map((track, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: index * 0.2 }}
                className={`relative p-6 sm:p-8 rounded-3xl shadow-2xl overflow-hidden bg-white ${
                  track.isFutureModule ? 'opacity-60' : 'opacity-100'
                }`}
                onMouseEnter={() => setHoveredTrack(index)}
                onMouseLeave={() => setHoveredTrack(null)}
              >
                <div className="relative z-10">
                  <motion.div
                    className="flex justify-center mb-6 sm:mb-8"
                    initial={{ rotate: 0 }}
                    animate={{ rotate: 360 }}
                    transition={{ duration: 20, repeat: Infinity, ease: 'linear' }}
                  >
                    {React.cloneElement(track.icon, {
                      className:
                        'w-16+ h-16+ sm:w-20 sm:h-20 md:w-24 md:h-24 p-4 sm:p-5 bg-gradient-to-br from-orange-400 to-orange-600 text-white rounded-full shadow-lg',
                    })}
                  </motion.div>
                  <h3
                    className="text-2xl sm:text-3xl font-bold mb-3 sm:mb-4 text-center text-orange-600"
                  >
                    {track.title}
                  </h3>
                  <p className="text-lg sm:text-xl mb-5 sm:mb-6 text-center font-medium text-gray-700">
                    {track.age}
                  </p>
                  <ul className="space-y-3 sm:space-y-4 mb-6 sm:mb-8">
                    {track.courses.map((course, i) => (
                      <motion.li
                        key={i}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: index * 0.2 + i * 0.1 }}
                        className="flex items-center text-gray-700 text-base sm:text-lg"
                      >
                        <ChevronRight size={20} className="mr-2 sm:mr-3 text-orange-500 flex-shrink-0" />
                        <span className="font-medium">{course}</span>
                      </motion.li>
                    ))}
                  </ul>
                  <div className="border-t border-gray-200 pt-5 sm:pt-6 mt-5 sm:mt-6">
                    <div className="flex items-center mb-3">
                      <Clock size={18} className="mr-2 sm:mr-3 text-orange-500 flex-shrink-0" />
                      <span className="font-medium text-gray-700 text-base sm:text-lg">
                        {language === 'bn' ? 'সময়কাল:' : 'Duration:'}
                      </span>
                      <span className="font-semibold ml-auto text-gray-700 text-base sm:text-lg">
                        {track.duration}
                      </span>
                    </div>
                    <div className="flex items-center mb-3">
                      <Zap size={18} className="mr-2 sm:mr-3 text-orange-500 flex-shrink-0" />
                      <span className="font-medium text-gray-700 text-base sm:text-lg">
                        {language === 'bn' ? 'মাসিক ফি:' : 'Monthly Fee:'}
                      </span>
                      <span className="font-semibold ml-auto text-gray-700 text-base sm:text-lg">
                        {track.monthlyFee}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <Star size={18} className="mr-2 sm:mr-3 text-orange-500 flex-shrink-0" />
                      <span className="font-medium text-gray-700 text-base sm:text-lg">
                        {language === 'bn' ? 'ত্রৈমাসিক ফি:' : 'Quarterly Fee:'}
                      </span>
                      <span className="font-semibold ml-auto text-gray-700 text-base sm:text-lg">
                        {track.quarterlyFee}
                      </span>
                    </div>
                  </div>
                  <div className="mt-6 sm:mt-8 text-center">
                    {track.isFutureModule ? (
                      <span
                        className="bg-gray-200 px-6 sm:px-8 py-3 sm:py-4 rounded-full text-lg sm:text-xl font-semibold shadow-lg inline-block cursor-not-allowed opacity-80 text-orange-600"
                      >
                        {language === 'bn' ? 'শীঘ্রই আসছে' : 'Coming Soon'}
                      </span>
                    ) : (
                      <Link to="/register">
                        <motion.button
                          whileHover={{
                            scale: 1.05,
                            boxShadow: '0 0 20px rgba(249, 115, 22, 0.6)',
                          }}
                          whileTap={{ scale: 0.95 }}
                          className="px-6 sm:px-8 py-3 sm:py-4 rounded-full text-lg sm:text-xl font-semibold transition duration-300 shadow-lg bg-gradient-to-r from-orange-500 to-orange-600 text-white"
                        >
                          {language === 'bn' ? 'এনরোল করুন' : 'Enroll Now'}
                        </motion.button>
                      </Link>
                    )}
                  </div>
                </div>
                <AnimatePresence>
                  {hoveredTrack === index && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="absolute inset-0 bg-gradient-to-br from-orange-200 to-orange-400 opacity-20"
                      style={{ mixBlendMode: 'overlay' }}
                    />
                  )}
                </AnimatePresence>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Programs;
