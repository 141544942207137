// src/ThreeScene.js
import * as THREE from 'three';

export const createThreeScene = (mountRef, sceneRef) => {
    let scene, camera, renderer, particles;

    const init = () => {
        scene = new THREE.Scene();
        camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        renderer = new THREE.WebGLRenderer({ alpha: true });
        renderer.setSize(window.innerWidth, window.innerHeight);
        mountRef.current.appendChild(renderer.domElement);

        const geometry = new THREE.BufferGeometry();
        const vertices = [];

        for (let i = 0; i < 10000; i++) {
            vertices.push(
                THREE.MathUtils.randFloatSpread(2000),
                THREE.MathUtils.randFloatSpread(2000),
                THREE.MathUtils.randFloatSpread(2000)
            );
        }

        geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

        const material = new THREE.PointsMaterial({ color: 0xFFA500, size: 2 });
        particles = new THREE.Points(geometry, material);
        scene.add(particles);

        camera.position.z = 1000;

        sceneRef.current = { scene, camera, renderer, particles };
    };

    const animate = () => {
        if (sceneRef.current) {
            const { particles, renderer, scene, camera } = sceneRef.current;
            requestAnimationFrame(animate);
            particles.rotation.x += 0.0001;
            particles.rotation.y += 0.0001;
            renderer.render(scene, camera);
        }
    };

    const handleResize = () => {
        if (sceneRef.current) {
            const { camera, renderer } = sceneRef.current;
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
        }
    };

    window.addEventListener('resize', handleResize);

    // Initialize scene and animation
    init();
    animate();

    return () => {
        window.removeEventListener('resize', handleResize);

        // Clean up Three.js objects
        if (sceneRef.current) {
            const { renderer, particles } = sceneRef.current;

            // Dispose of geometry and material
            particles.geometry.dispose();
            particles.material.dispose();

            // Dispose of renderer
            renderer.dispose();

            // Remove renderer's canvas element
            if (mountRef.current) {
                mountRef.current.removeChild(renderer.domElement);
            }
        }
    };
};