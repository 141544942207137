// RegistrationsTable.js
import React, { useState } from 'react';
import { Laptop, Wifi, Clipboard } from 'lucide-react';
import { motion } from 'framer-motion';

const RegistrationsTable = ({ filteredRegistrations, isLoading, error, determineTrack }) => {
  const [showCopiedPopup, setShowCopiedPopup] = useState(false);

  const handleCopy = (reg) => {
    const registrationDetails = `
      ${reg.student_name}
      ${reg.age}
      ${reg.school_name}
      ${reg.parents_name}
      ${reg.parents_mobile}
      ${reg.preferred_class_type}
      ${determineTrack(reg.age)}
      ${reg.has_computer ? 'Yes' : 'No'}
      ${reg.has_wifi ? 'Yes' : 'No'}
      ${reg.location}
      ${new Date(reg.registration_date).toLocaleString()}
    `;

    navigator.clipboard.writeText(registrationDetails.trim())
      .then(() => {
        setShowCopiedPopup(true);  // Show the "Copied" popup
        setTimeout(() => {
          setShowCopiedPopup(false);  // Hide the "Copied" popup after 2 seconds
        }, 2000);
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };

  return isLoading ? (
    <div className="text-center py-8">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-orange-500 mx-auto"></div>
      <p className="mt-4 text-lg">Loading registrations...</p>
    </div>
  ) : error ? (
    <div className="text-center py-8 text-red-600">
      <p>{error}</p>
    </div>
  ) : (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.4 }}
      className="relative bg-white shadow-lg rounded-2xl overflow-hidden"
    >
      {showCopiedPopup && (
        <div className="absolute top-4 right-4 bg-green-500 text-white py-2 px-4 rounded-lg shadow-lg z-50">
          Copied to clipboard!
        </div>
      )}

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead className="bg-orange-500 text-white">
            <tr>
              <th className="py-3 px-4 text-left">Copy</th>
              <th className="py-3 px-4 text-left">Student Name</th>
              <th className="py-3 px-4 text-left">Age</th>
              <th className="py-3 px-4 text-left">School Name</th>
              <th className="py-3 px-4 text-left">Parents' Name</th>
              <th className="py-3 px-4 text-left">Parents' Contact</th>
              <th className="py-3 px-4 text-left">Class Type</th>
              <th className="py-3 px-4 text-left">Track</th>
              <th className="py-3 px-4 text-left">Computer</th>
              <th className="py-3 px-4 text-left">Internet</th>
              <th className="py-3 px-4 text-left">Location</th>
              <th className="py-3 px-4 text-left">Registration Date</th>
            </tr>
          </thead>
          <tbody>
            {filteredRegistrations.length > 0 ? (
              filteredRegistrations.map((reg, index) => (
                <motion.tr
                  key={reg.id}
                  className={index % 2 === 0 ? 'bg-orange-50' : 'bg-white'}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.05 }}
                >
                  <td className="py-3 px-4 whitespace-nowrap">
                    <button
                      onClick={() => handleCopy(reg)}
                      className="flex items-center text-orange-600 hover:text-orange-800 transition-colors"
                    >
                      <Clipboard size={20} className="mr-2" />
                      Copy
                    </button>
                  </td>
                  <td className="py-3 px-4 whitespace-nowrap">{reg.student_name}</td>
                  <td className="py-3 px-4 whitespace-nowrap">{reg.age}</td>
                  <td className="py-3 px-4 whitespace-nowrap">{reg.school_name}</td>
                  <td className="py-3 px-4 whitespace-nowrap">{reg.parents_name}</td>
                  <td className="py-3 px-4 whitespace-nowrap">{reg.parents_mobile}</td>
                  <td className="py-3 px-4 whitespace-nowrap">
                    <span
                      className={`px-2 py-1 rounded-full text-xs font-semibold ${
                        reg.preferred_class_type === 'OFFLINE'
                          ? 'bg-blue-100 text-blue-800'
                          : reg.preferred_class_type === 'ONLINE'
                          ? 'bg-green-100 text-green-800'
                          : 'bg-purple-100 text-purple-800'
                      }`}
                    >
                      {reg.preferred_class_type}
                    </span>
                  </td>
                  <td className="py-3 px-4 whitespace-nowrap">{determineTrack(reg.age)}</td>
                  <td className="py-3 px-4 whitespace-nowrap text-center">
                    {reg.has_computer ? <Laptop size={20} className="text-green-500 inline" /> : <span className="text-red-500">✕</span>}
                  </td>
                  <td className="py-3 px-4 whitespace-nowrap text-center">
                    {reg.has_wifi ? <Wifi size={20} className="text-green-500 inline" /> : <span className="text-red-500">✕</span>}
                  </td>
                  <td className="py-3 px-4 whitespace-nowrap">{reg.location}</td>
                  <td className="py-3 px-4 whitespace-nowrap">{new Date(reg.registration_date).toLocaleString()}</td>
                </motion.tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" className="py-8 text-center text-gray-500">
                  No registrations found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </motion.div>
  );
};

export default RegistrationsTable;
