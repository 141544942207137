import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { createThreeScene } from './ThreeScene'; // Import the new ThreeScene.js
import { Code, Brain, Rocket, ChevronRight, Users, Star, Award, Clock, Lightbulb, Smile, Zap, Book } from 'lucide-react';
import CodeBlocksGame from './CodeBlocksGame';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Font Awesome CSS
import '@fortawesome/fontawesome-free/css/all.min.css';

const HomePage = ({ language }) => {
    const mountRef = useRef(null);
    const sceneRef = useRef(null);

    useEffect(() => {
        const cleanupThreeScene = createThreeScene(mountRef, sceneRef);

        // Cleanup function for when component unmounts
        return cleanupThreeScene;
    }, []);

    const content = {
        hero: {
            title: language === 'bn' ? 'নেক্সটজেন প্রোগ্রামার' : 'NextGen Programmer',
            subtitle: language === 'bn' ? 'উদ্ভাবনী কোডিং শিক্ষার মাধ্যমে তরুণ মনকে ক্ষমতায়ন' : 'Empowering Young Minds through Innovative Coding Education',
            cta: language === 'bn' ? 'বিনামূল্যে  ক্লাস বুক করুন' : 'Book Your Free  Class Now',
        },
        slider: [
            {
                image: '/images/slider1.jpg',
                title: language === 'bn' ? 'ইন্টারেক্টিভ কোডিং ক্লাস' : 'Interactive Coding Classes',
                description: language === 'bn' ? 'আমাদের অভিজ্ঞ শিক্ষকদের সাথে হাতে-কলমে শিখুন' : 'Learn hands-on with our experienced instructors',
            },
            {
                image: '/images/slider2.jpg',
                title: language === 'bn' ? 'প্রযুক্তিগত দক্ষতা অর্জন করুন' : 'Gain Tech Skills',
                description: language === 'bn' ? 'ভবিষ্যতের জন্য নিজেকে প্রস্তুত করুন' : 'Prepare yourself for the future',
            },
            {
                image: '/images/slider3.jpg',
                title: language === 'bn' ? 'সৃজনশীল প্রকল্প' : 'Creative Projects',
                description: language === 'bn' ? 'নিজের ধারণাগুলোকে বাস্তবে রূপ দিন' : 'Bring your ideas to life',
            },
        ],
        about: {
            title: language === 'bn' ? 'আমাদের সম্পর্কে জানুন' : 'Learn About Us',
            whoWeAre: {
                title: language === 'bn' ? 'আমরা কারা?' : 'Who Are We?',
                description: language === 'bn'
                    ? 'নেক্সটজেন প্রোগ্রামার হলো বাংলাদেশের অগ্রণী কোডিং শিক্ষা প্ল্যাটফর্ম, যেখানে আমরা শিশু ও কিশোরদের ভবিষ্যতের জন্য প্রস্তুত করি। আমাদের দক্ষ শিক্ষকরা প্রতিটি শিক্ষার্থীর মধ্যে থাকা প্রতিভাকে জাগিয়ে তোলেন।'
                    : 'NextGen Programmer is Bangladesh\'s leading coding education platform, where we prepare children and teenagers for the future. Our skilled teachers awaken the talent within each student.',
            },
            ourGoal: {
                title: language === 'bn' ? 'আমাদের লক্ষ্য' : 'Our Goal',
                description: language === 'bn'
                    ? 'আমাদের লক্ষ্য হলো প্রতিটি শিশু ও কিশোরকে ডিজিটাল যুগের চ্যালেঞ্জ মোকাবেলায় সক্ষম করে তোলা। আমরা বিশ্বাস করি, প্রোগ্রামিং শিখে তারা শুধু কোড লিখতে শিখবে না, বরং জটিল সমস্যা সমাধানের দক্ষতাও অর্জন করবে।'
                    : 'Our goal is to empower every child and teenager to meet the challenges of the digital age. We believe that by learning programming, they will not only learn to write code but also acquire complex problem-solving skills.',
            },
            joinUs: {
                title: language === 'bn' ? 'আমাদের সাথে যোগ দিন' : 'Join Us',
                description: language === 'bn'
                    ? 'নেক্সটজেন প্রোগ্রামার-এ যোগ দিয়ে আপনার সন্তানকে ভবিষ্যতের জন্য প্রস্তুত করুন। আমাদের সাথে তারা শিখবে, বাড়বে, এবং উদ্ভাবন করবে।'
                    : 'Join NextGen Programmer to prepare your child for the future. With us, they will learn, grow, and innovate.',
                cta: language === 'bn' ? 'বিনামূল্যে ক্লাস বুক করুন' : 'Book Your Free Class',
            },
        },
        features: {
            title: language === 'bn' ? 'কেন নেক্সটজেন প্রোগ্রামার বেছে নেবেন?' : 'Why Choose NextGen Programmer?',
            items: [
                {
                    icon: <Code size={48} />,
                    title: language === 'bn' ? 'ইন্টারেক্টিভ কোডিং' : 'Interactive Coding',
                    description: language === 'bn' ? 'হাতে-কলমে প্রোগ্রামিং ধারণা শিখুন' : 'Learn programming concepts hands-on',
                },
                {
                    icon: <Brain size={48} />,
                    title: language === 'bn' ? 'ক্রিটিক্যাল থিংকিং' : 'Critical Thinking',
                    description: language === 'bn' ? 'সমস্যা সমাধানের দক্ষতা উন্নয়ন করুন' : 'Develop problem-solving skills',
                },
                {
                    icon: <Rocket size={48} />,
                    title: language === 'bn' ? 'ভবিষ্যত প্রস্তুতি' : 'Future-Ready',
                    description: language === 'bn' ? 'ডিজিটাল যুগের জন্য প্রস্তুত হোন' : 'Prepare for the digital age',
                },
            ],
        },
        tracks: {
            title: language === 'bn' ? 'আমাদের লার্নিং মডিউল' : 'Our Learning Modules',
            items: [
                {
                    title: language === 'bn' ? 'ফাউন্ডেশন অব প্রোগ্রামিং' : 'Foundation of Programming',
                    age: language === 'bn' ? '৬-৮ বছর' : '6-8 years',
                    color: 'from-purple-400 to-indigo-600',
                    icon: <Rocket className="w-12 h-12 mb-4" />,
                    courses: language === 'bn'
                        ? ['কম্পিউটার বেসিক', 'Scratch Jr.', 'Scratch Advance', 'ওয়েব ডিজাইন']
                        : ['Computer Basic', 'Scratch Jr.', 'Scratch Advance', 'Web Design'],
                    duration: language === 'bn' ? '১২ মাস' : '12 months',
                    monthlyFee: language === 'bn' ? '১৪৯৯/- টাকা' : '1499/- BDT',
                    quarterlyFee: language === 'bn' ? '৪৪৯৭/- টাকা' : '4497/- BDT',
                },
                {
                    title: language === 'bn' ? 'ইন্টারমেডিয়েট প্রোগ্রামিং এন্ড ওয়েব ডেভেলপমেন্ট' : 'Intermediate Programming and Web Development',
                    age: language === 'bn' ? '৯-১২ বছর' : '9-12 years',
                    color: 'from-green-400 to-teal-600',
                    icon: <Code className="w-12 h-12 mb-4" />,
                    courses: language === 'bn'
                        ? ['কম্পিউটার বেসিক', 'Scratch Advanced', 'মোবাইল অ্যাপ ডেভেলপমেন্ট', 'ওয়েবসাইট বিল্ডিং বেসিক']
                        : ['Computer Basic', 'Scratch Advanced', 'Mobile App Development', 'Website Building Basics'],
                    duration: language === 'bn' ? '১২ মাস' : '12 months',
                    monthlyFee: language === 'bn' ? '১৪৯৯/- টাকা' : '1499/- BDT',
                    quarterlyFee: language === 'bn' ? '৪৪৯৭/- টাকা' : '4497/- BDT',
                },
                {
                    title: language === 'bn' ? 'এডভান্স প্রোগ্রামিং এন্ড ওয়েব টেকনোলজি' : 'Advanced Programming and Web Technology',
                    age: language === 'bn' ? '১৩-১৬+ বছর' : '13-16+ years',
                    color: 'from-red-400 to-pink-600',
                    icon: <Brain className="w-12 h-12 mb-4" />,
                    courses: language === 'bn'
                        ? ['কোর প্রোগ্রামিং ফান্ডামেন্টালস', 'অ্যাডভান্সড ওয়েব ডিজাইন', 'পাইথন ওয়েব ডেভেলপমেন্ট']
                        : ['Core Programming Fundamentals', 'Advanced Web Design', 'Python Web Development'],
                    duration: language === 'bn' ? '১২ মাস' : '12 months',
                    monthlyFee: language === 'bn' ? '১৪৯৯/- টাকা' : '1499/- BDT',
                    quarterlyFee: language === 'bn' ? '৪৪৯৭/- টাকা' : '4497/- BDT',
                },
            ],
        },
        interactiveGame: {
            title: language === 'bn' ? 'আমাদের ইন্টারেক্টিভ লার্নিং অভিজ্ঞতা' : 'Experience Our Interactive Learning',
            description: language === 'bn'
                ? 'আমাদের কোড ব্লকস গেম খেলুন এবং আমাদের আকর্ষণীয় পাঠ্যক্রমের একটি ঝলক পান!'
                : 'Try our Code Blocks Game and get a taste of our engaging curriculum!',
        },
        testimonials: {
            title: language === 'bn' ? 'সাফল্যের গল্প' : 'Success Stories',
            items: [
                {
                    name: language === 'bn' ? 'তানভীর আহমেদ' : 'Tanvir Ahmed',
                    age: language === 'bn' ? '১২ বছর' : '12 years old',
                    quote: language === 'bn'
                        ? 'নেক্সটজেন প্রোগ্রামার-এর সাথে আমি নিজের গেম তৈরি করেছি!'
                        : 'I built my own game with NextGen Programmer!',
                },
                {
                    name: language === 'bn' ? 'নুসরাত জাহান' : 'Nusrat Jahan',
                    age: language === 'bn' ? '১৫ বছর' : '15 years old',
                    quote: language === 'bn'
                        ? 'পাইথন শেখার মাধ্যমে আমার জন্য একটি নতুন জগত খুলে গেছে।'
                        : 'Learning Python opened up a whole new world for me.',
                },
                {
                    name: language === 'bn' ? 'রাফি ইসলাম' : 'Rafi Islam',
                    age: language === 'bn' ? '৯ বছর' : '9 years old',
                    quote: language === 'bn'
                        ? 'কোডিং এখন আমার স্কুল প্রজেক্টগুলোকে আরও মজাদার করে তুলেছে।'
                        : 'Coding makes my school projects so much cooler now.',
                },
            ],
        },
        whyCoding: {
            title: language === 'bn' ? 'কেন শিশুদের জন্য কোডিং?' : 'Why Coding for Kids?',
            items: [
                {
                    icon: <Brain size={40} />,
                    title: language === 'bn' ? 'সমস্যা সমাধান দক্ষতা বাড়ায়' : 'Enhances Problem-Solving',
                    description: language === 'bn'
                        ? 'কোডিং শিশুদের জটিল সমস্যাগুলো পদ্ধতিগতভাবে সমাধান করতে শেখায়।'
                        : 'Coding teaches kids to approach complex problems systematically.',
                },
                {
                    icon: <Rocket size={40} />,
                    title: language === 'bn' ? 'সৃজনশীলতা বাড়ায়' : 'Boosts Creativity',
                    description: language === 'bn'
                        ? 'প্রোগ্রামিং শিশুদের কল্পনাপ্রসূত ধারণাগুলোকে বাস্তবে রূপ দিতে সাহায্য করে।'
                        : 'Programming allows children to bring their imaginative ideas to life.',
                },
                {
                    icon: <Award size={40} />,
                    title: language === 'bn' ? 'একাডেমিক পারফরম্যান্স উন্নত করে' : 'Improves Academic Performance',
                    description: language === 'bn'
                        ? 'কোডিং দক্ষতা প্রায়শই গণিত ও বিজ্ঞানে ভালো ফলাফলের সাথে সম্পর্কিত।'
                        : 'Coding skills often translate to better performance in math and science.',
                },
                {
                    icon: <Clock size={40} />,
                    title: language === 'bn' ? 'ভবিষ্যতের জন্য প্রস্তুত করে' : 'Prepares for the Future',
                    description: language === 'bn'
                        ? 'আমাদের ডিজিটাল বিশ্বে, কোডিং অনেক পেশার জন্য একটি অপরিহার্য দক্ষতা হয়ে উঠছে।'
                        : 'In our digital world, coding is becoming an essential skill for many careers.',
                },
            ],
        },
        callToAction: {
            title: language === 'bn' ? 'আজই আপনার সন্তানের কোডিং যাত্রা শুরু করুন' : 'Start Your Child\'s Coding Journey Today',
            description: language === 'bn'
                ? 'নেক্সটজেন প্রোগ্রামার-এ যোগ দিন এবং কোডিংয়ের মাধ্যমে সম্ভাবনার এক নতুন জগৎ উন্মোচন করুন'
                : 'Join NextGen Programmer and unlock a world of possibilities through coding',
            cta: language === 'bn' ? 'বিনামূল্যে  ক্লাস বুক করুন' : 'Book Your Free  Class',
        },
    };

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <div className="bg-gradient-to-br from-orange-50 to-orange-100 min-h-screen">
            {/* WhatsApp Floating Button */}
            <motion.a
                href="https://wa.me/8801302064001?text=Hello!%20I%20would%20like%20to%20know%20more%20about%20your%20services."
                className="fixed bottom-5 right-5 bg-gradient-to-r from-green-400 to-green-600 text-white rounded-full p-3 shadow-lg hover:shadow-xl transition-all duration-300 group"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
            >
                <div className="relative">
                    <i className="fab fa-whatsapp text-3xl group-hover:animate-pulse"></i>
                    <motion.span
                        className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center"
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{
                            type: "spring",
                            stiffness: 260,
                            damping: 20,
                            delay: 0.5
                        }}
                    >
                        1
                    </motion.span>
                </div>
                <motion.span
                    className="absolute left-full ml-2 bg-white text-green-600 px-2 py-1 rounded text-sm whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                    initial={{ x: 20, opacity: 0 }}
                    whileHover={{ x: 0, opacity: 1 }}
                >
                    {language === 'bn' ? 'আমাদের সাথে চ্যাট করুন' : 'Chat with us'}
                </motion.span>
            </motion.a>

            {/* Messenger Floating Button */}
            <motion.a
                href="https://m.me/208941752311725"
                className="fixed bottom-20 right-5 bg-gradient-to-r from-blue-400 to-blue-600 text-white rounded-full p-4 shadow-lg hover:shadow-xl transition-all duration-300 group"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
            >
                <div className="relative">
                    <i className="fab fa-facebook-messenger text-3xl group-hover:animate-pulse"></i>
                    <motion.span
                        className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center"
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{
                            type: "spring",
                            stiffness: 260,
                            damping: 20,
                            delay: 0.5
                        }}
                    >
                        1
                    </motion.span>
                </div>
                <motion.span
                    className="absolute left-full ml-2 bg-white text-blue-600 px-2 py-1 rounded text-sm whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                    initial={{ x: 20, opacity: 0 }}
                    whileHover={{ x: 0, opacity: 1 }}
                >
                    {language === 'bn' ? 'মেসেঞ্জারে চ্যাট করুন' : 'Chat on Messenger'}
                </motion.span>
            </motion.a>

            {/* Hero Section */}
            <div className="relative h-screen">
                <div ref={mountRef} className="absolute inset-0" />
                <motion.section 
                    initial={{opacity: 0}} 
                    animate={{opacity: 1}} 
                    transition={{duration: 1}} 
                    className="relative z-10 container mx-auto px-4 py-20 text-center flex flex-col justify-center h-full"
                >
                    <motion.h1 
                        initial={{y: -50}} 
                        animate={{y: 0}} 
                        transition={{type: "spring", stiffness: 300}}
                        className="text-4xl md:text-5xl lg:text-7xl font-bold text-orange-500 mb-6 text-shadow-lg"
                    >
                        {content.hero.title}
                    </motion.h1>
                    <motion.p 
                        initial={{y: 50}} 
                        animate={{y: 0}} 
                        transition={{type: "spring", stiffness: 300, delay: 0.2}}
                        className="text-lg md:text-xl lg:text-2xl text-gray-400 mb-12 text-shadow"
                    >
                        {content.hero.subtitle}
                    </motion.p>
                    <motion.div
                        initial={{y: 50, opacity: 0}} 
                        animate={{y: 0, opacity: 1}} 
                        transition={{type: "spring", stiffness: 300, delay: 0.4}}
                    >
                        <Link to="/register">
                            <motion.button 
                                whileHover={{scale: 1.05, boxShadow: "0px 0px 8px rgb(255,16+5,0)"}} 
                                whileTap={{scale: 0.95}}
                                className="bg-orange-500 text-white px-8 py-4 rounded-full text-lg font-semibold hover:bg-orange-600 transition duration-300 shadow-lg"
                            >
                                {content.hero.cta}
                            </motion.button>
                        </Link>
                    </motion.div>
                </motion.section>
            </div>

            {/* About Section */}
            <section className="py-20 bg-white" id="about">
                <div className="container mx-auto px-4">
                    <motion.h2
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.5}}
                        className="text-3xl md:text-4xl font-bold text-center text-gray-800 mb-16+"
                    >
                        {content.about.title}
                    </motion.h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                        <motion.div
                            initial={{opacity: 0, x: -50}}
                            animate={{opacity: 1, x: 0}}
                            transition={{duration: 0.5, delay: 0.2}}
                            className="space-y-6"
                        >
                            <h3 className="text-2xl font-semibold text-orange-600">{content.about.whoWeAre.title}</h3>
                            <p className="text-gray-700">{content.about.whoWeAre.description}</p>
                            <motion.div
                                className="flex items-center space-x-4"
                                whileHover={{scale: 1.05}}
                            >
                                <Lightbulb className="text-yellow-500" size={30}/>
                                <span className="text-lg font-medium">{language === 'bn' ? 'উদ্ভাবনী শিক্ষা পদ্ধতি' : 'Innovative Teaching Method'}</span>
                            </motion.div>
                            <motion.div
                                className="flex items-center space-x-4"
                                whileHover={{scale: 1.05}}
                            >
                                <Smile className="text-green-500" size={30}/>
                                <span className="text-lg font-medium">{language === 'bn' ? 'আনন্দদায়ক শিখন পরিবেশ' : 'Fun Learning Environment'}</span>
                            </motion.div>
                        </motion.div>
                        <motion.div
                            initial={{opacity: 0, x: 50}}
                            animate={{opacity: 1, x: 0}}
                            transition={{duration: 0.5, delay: 0.4}}
                            className="space-y-6"
                        >
                            <h3 className="text-2xl font-semibold text-orange-600">{content.about.ourGoal.title}</h3>
                            <p className="text-gray-700">{content.about.ourGoal.description}</p>
                            <motion.div
                                className="flex items-center space-x-4"
                                whileHover={{scale: 1.05}}
                            >
                                <Zap className="text-blue-500" size={30}/>
                                <span className="text-lg font-medium">{language === 'bn' ? 'দক্ষতা উন্নয়ন' : 'Skill Development'}</span>
                            </motion.div>
                            <motion.div
                                className="flex items-center space-x-4"
                                whileHover={{scale: 1.05}}
                            >
                                <Book className="text-purple-500" size={30}/>
                                <span className="text-lg font-medium">{language === 'bn' ? 'জীবনব্যাপী শিক্ষা' : 'Lifelong Learning'}</span>
                            </motion.div>
                        </motion.div>
                    </div>
                    <motion.div
                        initial={{opacity: 0, y: 50}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.5, delay: 0.6}}
                        className="mt-16+ text-center"
                    >
                        <h3 className="text-2xl font-semibold text-orange-600 mb-4">{content.about.joinUs.title}</h3>
                        <p className="text-gray-700 mb-8">{content.about.joinUs.description}</p>
                        <Link to="/register">
                            <motion.button
                                whileHover={{scale: 1.05, rotate: [0, -5, 5, -5, 0]}}
                                whileTap={{scale: 0.95}}
                                className="bg-orange-500 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-orange-600 transition duration-300 shadow-lg"
                            >
                                {content.about.joinUs.cta}
                            </motion.button>
                        </Link>
                    </motion.div>
                </div>
            </section>

            {/* Features Section */}
            <section className="bg-orange-50 py-20" id="features">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl md:text-4xl font-bold text-center text-gray-800 mb-16+">{content.features.title}</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                        {content.features.items.map((feature, index) => (
                            <motion.div
                                key={index}
                                initial={{opacity: 0, y: 50}}
                                animate={{opacity: 1, y: 0}}
                                transition={{delay: index * 0.2}}
                                className="bg-white p-8 rounded-xl shadow-lg text-center"
                            >
                                <div className="text-orange-500 mb-4">{feature.icon}</div>
                                <h3 className="text-xl md:text-2xl font-semibold text-gray-800 mb-4">{feature.title}</h3>
                                <p className="text-gray-600">{feature.description}</p>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Updated Course Tracks Section */}
            <section className="py-20 relative overflow-hidden">
                <div className="container mx-auto px-4">
                    <h2 className="text-4xl md:text-5xl font-bold text-center mb-16+ relative z-10">
                        <span className="bg-clip-text text-transparent bg-gradient-to-r from-orange-400 to-red-600">
                            {content.tracks.title}
                        </span>
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 relative z-10">
                        {content.tracks.items.map((track, index) => (
                            <motion.div
                                key={index}
                                initial={{opacity: 0, y: 50}}
                                animate={{opacity: 1, y: 0}}
                                transition={{delay: index * 0.2, duration: 0.5}}
                                className={`bg-gradient-to-br ${track.color} text-white p-8 rounded-2xl shadow-2xl transform hover:scale-105 transition-all duration-300`}
                            >
                                <div className="flex justify-center mb-6">
                                    {index === 0 && <Rocket className="w-16+ h-16+ text-yellow-300"/>}
                                    {index === 1 && <Code className="w-16+ h-16+ text-yellow-300"/>}
                                    {index === 2 && <Brain className="w-16+ h-16+ text-yellow-300"/>}
                                </div>
                                <h3 className="text-2xl font-semibold mb-2 text-center">{track.title}</h3>
                                <p className="text-lg mb-4 text-center font-light">{track.age}</p>
                                <ul className="space-y-3 mb-6">
                                    {track.courses.map((course, i) => (
                                        <motion.li
                                            key={i}
                                            initial={{opacity: 0, x: -20}}
                                            animate={{opacity: 1, x: 0}}
                                            transition={{delay: (index * 0.2) + (i * 0.1)}}
                                            className="flex items-center"
                                        >
                                            <ChevronRight size={20} className="mr-2 text-yellow-300"/>
                                            <span className="font-light">{course}</span>
                                        </motion.li>
                                    ))}
                                </ul>
                                <div className="border-t border-white/30 pt-4 mt-4">
                                    <div className="flex items-center mb-2">
                                        <Clock size={18} className="text-yellow-300 mr-2"/>
                                        <span
                                            className="font-light">{language === 'bn' ? 'সময়কাল:' : 'Duration:'}</span>
                                        <span className="font-semibold ml-auto">{track.duration}</span>
                                    </div>
                                    <div className="flex items-center mb-2">
                                        <Zap size={18} className="text-yellow-300 mr-2"/>
                                        <span
                                            className="font-light">{language === 'bn' ? 'মাসিক ফি:' : 'Monthly Fee:'}</span>
                                        <span className="font-semibold ml-auto">{track.monthlyFee}</span>
                                    </div>
                                    <div className="flex items-center">
                                        <Star size={18} className="text-yellow-300 mr-2"/>
                                        <span
                                            className="font-light">{language === 'bn' ? 'ত্রৈমাসিক ফি:' : 'Quarterly Fee:'}</span>
                                        <span className="font-semibold ml-auto">{track.quarterlyFee}</span>
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </div>
                <div className="absolute top-0 left-0 w-full h-full z-0">
                    <div
                        className="absolute top-10 left-10 w-20 h-20 bg-yellow-300 rounded-full opacity-20 animate-pulse"></div>
                    <div
                        className="absolute bottom-10 right-10 w-32 h-32 bg-blue-300 rounded-full opacity-20 animate-pulse"></div>
                    <div
                        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-64 h-64 bg-purple-300 rounded-full opacity-10 animate-pulse"></div>
                </div>
            </section>

            {/* Interactive Game Section */}
            <section className="bg-white py-20">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl md:text-4xl font-bold text-center text-gray-800 mb-8">{content.interactiveGame.title}</h2>
                    <p className="text-lg md:text-xl text-center text-gray-600 mb-12">
                        {content.interactiveGame.description}
                    </p>
                    <CodeBlocksGame language={language}/>
                </div>
            </section>

            {/* Testimonials Section */}
            <section className="py-20" id="testimonials">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl md:text-4xl font-bold text-center text-gray-800 mb-16+">{content.testimonials.title}</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {content.testimonials.items.map((testimonial, index) => (
                            <motion.div
                                key={index}
                                initial={{opacity: 0, y: 50}}
                                animate={{opacity: 1, y: 0}}
                                transition={{delay: index * 0.2}}
                                className="bg-orange-50 p-6 rounded-xl shadow-lg"
                            >
                                <p className="text-gray-600 mb-4">"{testimonial.quote}"</p>
                                <div className="flex items-center">
                                    <div className="bg-orange-500 rounded-full p-2 mr-4">
                                        <Users size={24} className="text-white"/>
                                    </div>
                                    <div>
                                        <p className="font-semibold text-gray-800">{testimonial.name}</p>
                                        <p className="text-gray-600">{testimonial.age}</p>
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Why Coding for Kids Section */}
            <section className="bg-white py-20">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl md:text-4xl font-bold text-center text-gray-800 mb-16+">{content.whyCoding.title}</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        {content.whyCoding.items.map((item, index) => (
                            <motion.div
                                key={index}
                                initial={{opacity: 0, y: 20}}
                                animate={{opacity: 1, y: 0}}
                                transition={{delay: index * 0.1}}
                                className="text-center p-6 bg-orange-50 rounded-lg shadow-md"
                            >
                                <div className="text-orange-500 mb-4 flex justify-center">{item.icon}</div>
                                <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                                <p className="text-gray-600">{item.description}</p>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Call to Action */}
            <section className="bg-orange-600 py-20 text-white">
                <div className="container mx-auto px-4 text-center">
                    <h2 className="text-3xl md:text-4xl font-bold mb-8">{content.callToAction.title}</h2>
                    <p className="text-lg md:text-xl mb-12">{content.callToAction.description}</p>
                    <Link to="/register">
                        <motion.button
                            whileHover={{scale: 1.05}}
                            whileTap={{scale: 0.95}}
                            className="bg-white text-orange-600 px-8 py-3 rounded-full text-lg font-semibold hover:bg-orange-100 transition duration-300 shadow-lg"
                        >
                            {content.callToAction.cta}
                        </motion.button>
                    </Link>
                </div>
            </section>
        </div>
    );
};

export default HomePage;
