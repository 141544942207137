// AdminDashboard.js
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import { RefreshCw, Printer, LogOut, FileText } from 'lucide-react';
import Filters from './Filters';
import ClassTypeChart from './ClassTypeChart';
import TrackDistributionChart from './TrackDistributionChart';
import RegistrationsTable from './RegistrationsTable';
import { generatePDF } from './pdfGenerator';
import { generateExcel } from './excelGenerator';

// Moved 'determineTrack' function above its first usage
const determineTrack = (age) => {
  if (age >= 6 && age <= 8) return 'Track 1 (6-8)';
  if (age >= 9 && age <= 12) return 'Track 2 (9-12)';
  return 'Track 3 (13-16+)';
};

const AdminDashboard = () => {
  const [registrations, setRegistrations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [phoneSearchTerm, setPhoneSearchTerm] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterClassType, setFilterClassType] = useState('');
  const [filterTrack, setFilterTrack] = useState('');
  const [isPdfGenerating, setIsPdfGenerating] = useState(false);
  const navigate = useNavigate();

  const fetchRegistrations = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
      const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';
      const response = await axios.get(`${apiUrl}/trial-class/registrations`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const uniqueData = removeDuplicates(response.data);
      setRegistrations(uniqueData);
    } catch (error) {
      setError('Failed to fetch registrations. Please try again.');
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        localStorage.removeItem('token');
        navigate('/login');
      }
    } finally {
      setIsLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    fetchRegistrations();
  }, [fetchRegistrations]);

  const removeDuplicates = (data) => {
    const uniqueRegistrations = [];
    const keysSet = new Set();
    data.forEach((reg) => {
      const uniqueKey = `${reg.student_name}-${reg.age}-${reg.parents_name}-${reg.parents_mobile}`;
      if (!keysSet.has(uniqueKey)) {
        keysSet.add(uniqueKey);
        uniqueRegistrations.push(reg);
      }
    });
    return uniqueRegistrations;
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  // Filtering registrations by search term, phone number, date range, class type, and track
  const filteredRegistrations = registrations.filter((reg) => {
    const searchCheck =
      reg.student_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      reg.parents_name?.toLowerCase().includes(searchTerm.toLowerCase());
    const phoneCheck = reg.parents_mobile?.toLowerCase().includes(phoneSearchTerm.toLowerCase());
    const dateCheck =
      (!startDate || new Date(reg.registration_date) >= startDate) &&
      (!endDate || new Date(reg.registration_date) <= endDate);
    const classTypeCheck = !filterClassType || reg.preferred_class_type === filterClassType;
    const trackCheck = !filterTrack || determineTrack(reg.age) === filterTrack;
    return searchCheck && phoneCheck && dateCheck && classTypeCheck && trackCheck;
  });

  const handleGeneratePDF = async () => {
    setIsPdfGenerating(true);
    try {
      await generatePDF(filteredRegistrations);
    } catch (error) {
      alert(error.message);
    } finally {
      setIsPdfGenerating(false);
    }
  };

  const handleDownloadExcel = () => {
    generateExcel(filteredRegistrations);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-orange-50 to-orange-100 p-8">
      <div className="max-w-7xl mx-auto space-y-8">
        <header className="bg-white rounded-2xl shadow-lg p-6">
          <div className="flex justify-between items-center">
            <h1 className="text-4xl font-bold text-orange-600">NextGen Programmer Dashboard</h1>
            <div className="flex space-x-4">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={fetchRegistrations}
                className="bg-green-500 text-white px-6 py-3 rounded-full hover:bg-green-600 transition duration-300 flex items-center"
              >
                <RefreshCw size={20} className="mr-2" />
                Refresh
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleGeneratePDF}
                disabled={isPdfGenerating || filteredRegistrations.length === 0}
                className={`bg-blue-500 text-white px-6 py-3 rounded-full hover:bg-blue-600 transition duration-300 flex items-center ${
                  isPdfGenerating || filteredRegistrations.length === 0 ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                <Printer size={20} className="mr-2" />
                {isPdfGenerating ? 'Generating PDF...' : 'Print PDF'}
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleDownloadExcel}
                className="bg-yellow-500 text-white px-6 py-3 rounded-full hover:bg-yellow-600 transition duration-300 flex items-center"
              >
                <FileText size={20} className="mr-2" />
                Download Excel
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleLogout}
                className="bg-red-500 text-white px-6 py-3 rounded-full hover:bg-red-600 transition duration-300 flex items-center"
              >
                <LogOut size={20} className="mr-2" />
                Logout
              </motion.button>
            </div>
          </div>
        </header>

        <Filters
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          phoneSearchTerm={phoneSearchTerm}
          setPhoneSearchTerm={setPhoneSearchTerm}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          filterClassType={filterClassType}
          setFilterClassType={setFilterClassType}
          filterTrack={filterTrack}
          setFilterTrack={setFilterTrack}
        />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <ClassTypeChart filteredRegistrations={filteredRegistrations} />
          <TrackDistributionChart filteredRegistrations={filteredRegistrations} determineTrack={determineTrack} />
        </div>

        <RegistrationsTable
          filteredRegistrations={filteredRegistrations}
          isLoading={isLoading}
          error={error}
          determineTrack={determineTrack}
        />
      </div>
    </div>
  );
};

export default AdminDashboard;
