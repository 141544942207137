import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Phone, Mail, MapPin, Send, Sun, Cloud } from 'lucide-react';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted:', formData);
        setFormData({ name: '', email: '', message: '' });
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-orange-100 to-white py-12 relative overflow-hidden">
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 2 }}
                className="absolute inset-0 z-0"
                style={{
                    backgroundImage: 'url("data:image/svg+xml,%3Csvg width=\'100\' height=\'100\' viewBox=\'0 0 100 100\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z\' fill=\'%23ff9800\' fill-opacity=\'0.1\' fill-rule=\'evenodd\'/%3E%3C/svg%3E")',
                    backgroundSize: '150px 150px'
                }}
            />

            <div className="container mx-auto px-4 relative z-10">
                <motion.h1
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="text-5xl font-bold text-center text-orange-600 mb-12"
                >
                    যোগাযোগ করুন
                </motion.h1>

                <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    className="grid grid-cols-1 md:grid-cols-2 gap-12"
                >
                    <motion.div variants={itemVariants} className="bg-white rounded-lg shadow-lg p-8 relative overflow-hidden">
                        <div className="absolute top-0 right-0 -mr-4 -mt-4">
                            <motion.div
                                animate={{
                                    rotate: 360,
                                    transition: { duration: 50, repeat: Infinity, ease: "linear" }
                                }}
                            >
                                <Sun className="text-orange-300" size={100} />
                            </motion.div>
                        </div>
                        <h2 className="text-2xl font-semibold mb-6 text-orange-500">আমাদের সাথে যোগাযোগ করুন</h2>
                        <p className="text-gray-700 mb-8">
                            আপনার যে কোনো প্রশ্ন বা মতামত আমাদের জানাতে দ্বিধা করবেন না। আমরা সর্বদা আপনাদের সেবায় প্রস্তুত।
                        </p>
                        <div className="space-y-4">
                            <motion.div whileHover={{ x: 10 }} className="flex items-center">
                                <Phone className="text-orange-500 mr-4" />
                                <span>+৮৮০ ১৩০২০৬৪০০১, +৮৮০ ১৭১৭০৬৩১৯১</span>
                            </motion.div>
                            <motion.div whileHover={{ x: 10 }} className="flex items-center">
                                <Mail className="text-orange-500 mr-4" />
                                <span>info@nextgenprogrammer.com</span>
                            </motion.div>
                            <motion.div whileHover={{ x: 10 }} className="flex items-center">
                                <MapPin className="text-orange-500 mr-4" />
                                <span>আমাদের ঠিকানা:- ফেনী সুপার মার্কেট, ৪র্থ তলা, [উত্তর পাশের সিঁড়ি], দোয়েল চত্তর, ট্রাংক রোড ফেনী। (সাবেক সুরত মহল সিনেমা হল বিল্ডিং)</span>
                            </motion.div>
                        </div>
                    </motion.div>

                    <motion.div variants={itemVariants} className="relative">
                        <div className="absolute top-0 left-0 -ml-4 -mt-4">
                            <motion.div
                                animate={{
                                    y: [0, -10, 0],
                                    transition: { duration: 3, repeat: Infinity, ease: "easeInOut" }
                                }}
                            >
                                <Cloud className="text-orange-300" size={80} />
                            </motion.div>
                        </div>
                        <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow-lg p-8">
                            <div className="mb-4">
                                <label htmlFor="name" className="block text-gray-700 font-semibold mb-2">নাম</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 transition-all duration-300"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-gray-700 font-semibold mb-2">ইমেইল</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 transition-all duration-300"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="message" className="block text-gray-700 font-semibold mb-2">বার্তা</label>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    rows="4"
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 transition-all duration-300"
                                    required
                                ></textarea>
                            </div>
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                type="submit"
                                className="w-full bg-orange-500 text-white py-2 px-4 rounded-md font-semibold flex items-center justify-center transition-all duration-300 hover:bg-orange-600"
                            >
                                <Send className="mr-2" size={18} />
                                পাঠিয়ে দিন
                            </motion.button>
                        </form>
                    </motion.div>
                </motion.div>

                <motion.div
                    variants={itemVariants}
                    className="mt-16"
                >
                    <h2 className="text-2xl font-semibold mb-6 text-center text-orange-600">আমাদের অবস্থান</h2>
                    <div className="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden shadow-lg">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d918.0902688714332!2d91.39735447546894!3d23.010510995344116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375368310b5b8601%3A0x3949a1fdebf882e7!2sFeni%20Super%20Market%2C%20Surot%20Mohol%20Building!5e0!3m2!1sen!2sbd!4v1720894761643!5m2!1sen!2sbd"
                            width="100%"
                            height="450"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default Contact;