import * as XLSX from 'xlsx';

// Utility function to format the date
const formatDate = (date) => {
  const d = new Date(date);
  return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${d.getFullYear()}`;
};

// Function to auto-size the columns based on the content
const autoSizeColumns = (worksheet, data) => {
  const columnWidths = data.reduce((colWidths, row) => {
    Object.keys(row).forEach((key, index) => {
      const cellLength = row[key] ? row[key].toString().length : 10;
      colWidths[index] = Math.max(colWidths[index] || 10, cellLength);
    });
    return colWidths;
  }, []);

  worksheet['!cols'] = columnWidths.map((width) => ({ wch: width }));
};

// Function to determine the track based on age
const determineTrack = (age) => {
  if (age >= 6 && age <= 8) return 'Track 1 (6-8)';
  if (age >= 9 && age <= 12) return 'Track 2 (9-12)';
  return 'Track 3 (13-16+)';
};

export const generateExcel = (registrations) => {
  // Add custom headers and format the data, including the new "Track" column
  const formattedRegistrations = registrations.map((reg) => ({
    'Student Name': reg.student_name,
    'Age': reg.age,
    'School Name': reg.school_name,
    'Parents\' Name': reg.parents_name,
    'Parents\' Contact': reg.parents_mobile,
    'Preferred Class Type': reg.preferred_class_type,
    'Track': determineTrack(reg.age), // Include the Track information
    'Has Computer': reg.has_computer ? 'Yes' : 'No',
    'Has WiFi': reg.has_wifi ? 'Yes' : 'No',
    'Location': reg.location,
    'Registration Date': formatDate(reg.registration_date),
  }));

  // Create a new worksheet with custom headers and formatted data
  const worksheet = XLSX.utils.json_to_sheet(formattedRegistrations);

  // Auto-size the columns based on content
  autoSizeColumns(worksheet, formattedRegistrations);

  // Create a new workbook and append the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Registrations');

  // Apply some styling (Optional: basic for now, can be extended)
  const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
  for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
    worksheet[cellAddress].s = {
      font: { bold: true, color: { rgb: 'FFFFFF' } },
      fill: { fgColor: { rgb: '4F81BD' } },  // Light blue header background
      alignment: { horizontal: 'center' },
    };
  }

  // Trigger the file download with a dynamic filename
  const fileName = `registrations_${new Date().toISOString().split('T')[0]}.xlsx`;
  XLSX.writeFile(workbook, fileName);
};
