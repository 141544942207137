import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
    Brain,
    Gamepad,
    Globe,
    Smartphone,
    Book,
    Code,
    Calculator,
    Paintbrush,
    Music,
    Puzzle,
    PenTool,
} from 'lucide-react';

const Apps = () => {
    const [selectedApp, setSelectedApp] = useState(null);

    const apps = [
        {
            name: 'কোড কুইজ',
            icon: <Brain size={40} />,
            description: 'প্রোগ্রামিং বিষয়ক মজার কুইজ খেলুন এবং আপনার জ্ঞান যাচাই করুন।',
            component: CodeQuiz,
        },
        {
            name: 'স্ক্র্যাচ স্টুডিও',
            icon: <Gamepad size={40} />,
            description: 'অনলাইনে স্ক্র্যাচ প্রোজেক্ট তৈরি করুন এবং শেয়ার করুন।',
            component: ScratchStudio,
        },
        {
            name: 'পাইথন প্লেগ্রাউন্ড',
            icon: <Globe size={40} />,
            description: 'ব্রাউজারেই পাইথন কোড লিখুন এবং রান করুন।',
            component: PythonPlayground,
        },
        {
            name: 'অ্যাপ ইনভেন্টর',
            icon: <Smartphone size={40} />,
            description: 'নিজেই তৈরি করুন Android অ্যাপ, কোডিং জ্ঞান ছাড়াই।',
            component: AppInventor,
        },
        {
            name: 'ডিজিটাল নোটবুক',
            icon: <Book size={40} />,
            description: 'আপনার কোডিং নোট সংরক্ষণ করুন এবং শেয়ার করুন।',
            component: DigitalNotebook,
        },
        {
            name: 'রোবো সিমুলেটর',
            icon: <Code size={40} />,
            description: 'ভার্চুয়াল রোবট প্রোগ্রাম করুন এবং নিয়ন্ত্রণ করুন।',
            component: RoboSimulator,
        },
        {
            name: 'গণিত মজার খেলা',
            icon: <Calculator size={40} />,
            description: 'গণিতের মজার খেলায় অংশ নিন এবং আপনার গণিত দক্ষতা বাড়ান।',
            component: MathFunGame,
        },
        {
            name: 'আর্টিস্ট স্টুডিও',
            icon: <Paintbrush size={40} />,
            description: 'অনলাইনে ছবি আঁকুন এবং আপনার সৃজনশীলতা প্রকাশ করুন।',
            component: ArtistStudio,
        },
        {
            name: 'সঙ্গীত নির্মাতা',
            icon: <Music size={40} />,
            description: 'নিজেই তৈরি করুন সুর ও সঙ্গীত, মজার সাউন্ড ব্যবহার করে।',
            component: MusicMaker,
        },
        {
            name: 'শব্দ ধাঁধা',
            icon: <Puzzle size={40} />,
            description: 'শব্দের ধাঁধা সমাধান করুন এবং আপনার শব্দভাণ্ডার বৃদ্ধি করুন।',
            component: WordPuzzle,
        },
        {
            name: 'গল্প সৃষ্টিকারী',
            icon: <PenTool size={40} />,
            description: 'নিজের গল্প লিখুন এবং শেয়ার করুন অন্যদের সাথে।',
            component: StoryCreator,
        },
    ];

    return (
        <div className="min-h-screen bg-gradient-to-br from-orange-100 to-orange-200 py-12">
            <div className="container mx-auto px-4">
                <motion.h1
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="text-5xl font-bold text-center text-orange-600 mb-12"
                >
                    মজার লার্নিং অ্যাপস
                </motion.h1>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {apps.map((app, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className="bg-white rounded-lg shadow-lg overflow-hidden cursor-pointer"
                            onClick={() => setSelectedApp(app)}
                        >
                            <div className="p-6 bg-orange-500">
                                <div className="text-white mb-4 flex justify-center">{app.icon}</div>
                                <h2 className="text-2xl font-semibold mb-2 text-center text-white">{app.name}</h2>
                            </div>
                            <div className="p-4">
                                <p className="text-gray-600 text-center">{app.description}</p>
                            </div>
                        </motion.div>
                    ))}
                </div>

                <AnimatePresence>
                    {selectedApp && (
                        <motion.div
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.8 }}
                            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4"
                        >
                            <div className="bg-white rounded-lg p-8 max-w-4xl w-full max-h-[80vh] overflow-y-auto">
                                <h2 className="text-3xl font-bold mb-4 text-center">{selectedApp.name}</h2>
                                <selectedApp.component />
                                <div className="flex justify-center mt-6">
                                    <motion.button
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        className="bg-orange-500 text-white py-2 px-6 rounded-full font-semibold"
                                        onClick={() => setSelectedApp(null)}
                                    >
                                        বন্ধ করুন
                                    </motion.button>
                                </div>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>

                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.6 }}
                    className="mt-16 text-center"
                >
                    <h2 className="text-3xl font-semibold mb-4 text-orange-600">আপনার আইডিয়া শেয়ার করুন</h2>
                    <p className="text-gray-700 mb-8 text-lg">
                        আপনার কি কোনো নতুন অ্যাপের আইডিয়া আছে? আমাদের জানান, আমরা সেটি বাস্তবায়নের চেষ্টা করব!
                    </p>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="bg-orange-500 text-white py-3 px-8 rounded-full font-semibold text-lg"
                    >
                        আইডিয়া জমা দিন
                    </motion.button>
                </motion.div>
            </div>
        </div>
    );
};

const CodeQuiz = () => {
    return (
        <div>
            <h3>কোড কুইজ</h3>
            <p>এখানে আপনি প্রোগ্রামিং বিষয়ক কুইজ খেলতে পারেন।</p>
            {/* কোড কুইজের জন্য আরও কোড যুক্ত করুন */}
        </div>
    );
};

const ScratchStudio = () => {
    return (
        <div>
            <h3>স্ক্র্যাচ স্টুডিও</h3>
            <p>এখানে আপনি স্ক্র্যাচ প্রোজেক্ট তৈরি ও শেয়ার করতে পারেন।</p>
            {/* স্ক্র্যাচ স্টুডিওর জন্য আরও কোড যুক্ত করুন */}
        </div>
    );
};

const PythonPlayground = () => {
    return (
        <div>
            <h3>পাইথন প্লেগ্রাউন্ড</h3>
            <p>এখানে আপনি পাইথন কোড লিখতে ও রান করতে পারেন।</p>
            {/* পাইথন প্লেগ্রাউন্ডের জন্য আরও কোড যুক্ত করুন */}
        </div>
    );
};

const AppInventor = () => {
    return (
        <div>
            <h3>অ্যাপ ইনভেন্টর</h3>
            <p>এখানে আপনি নিজের Android অ্যাপ তৈরি করতে পারেন।</p>
            {/* অ্যাপ ইনভেন্টরের জন্য আরও কোড যুক্ত করুন */}
        </div>
    );
};

const DigitalNotebook = () => {
    return (
        <div>
            <h3>ডিজিটাল নোটবুক</h3>
            <p>এখানে আপনি আপনার কোডিং নোট সংরক্ষণ ও শেয়ার করতে পারেন।</p>
            {/* ডিজিটাল নোটবুকের জন্য আরও কোড যুক্ত করুন */}
        </div>
    );
};

const RoboSimulator = () => {
    return (
        <div>
            <h3>রোবো সিমুলেটর</h3>
            <p>এখানে আপনি ভার্চুয়াল রোবট প্রোগ্রাম করতে ও নিয়ন্ত্রণ করতে পারেন।</p>
            {/* রোবো সিমুলেটরের জন্য আরও কোড যুক্ত করুন */}
        </div>
    );
};

const MathFunGame = () => {
    const [num1, setNum1] = useState(null);
    const [num2, setNum2] = useState(null);
    const [operator, setOperator] = useState('+');
    const [userAnswer, setUserAnswer] = useState('');
    const [feedback, setFeedback] = useState('');
    const [score, setScore] = useState(0);

    const generateQuestion = () => {
        const operators = ['+', '-', '*'];
        setNum1(Math.floor(Math.random() * 10) + 1);
        setNum2(Math.floor(Math.random() * 10) + 1);
        setOperator(operators[Math.floor(Math.random() * operators.length)]);
        setUserAnswer('');
        setFeedback('');
    };

    useEffect(() => {
        generateQuestion();
    }, []);

    const checkAnswer = () => {
        let correctAnswer;
        switch (operator) {
            case '+':
                correctAnswer = num1 + num2;
                break;
            case '-':
                correctAnswer = num1 - num2;
                break;
            case '*':
                correctAnswer = num1 * num2;
                break;
            default:
                correctAnswer = num1 + num2;
        }

        if (parseInt(userAnswer) === correctAnswer) {
            setFeedback('সঠিক!');
            setScore(score + 1);
        } else {
            setFeedback(`ভুল। সঠিক উত্তর হলো ${correctAnswer}`);
        }
        setTimeout(() => {
            generateQuestion();
        }, 2000);
    };

    return (
        <div className="text-center">
            <h3 className="text-2xl font-bold mb-4">গণিত মজার খেলা</h3>
            <p className="text-lg mb-2">আপনার স্কোর: {score}</p>
            <div className="text-xl mb-4">
                {num1} {operator} {num2} = ?
            </div>
            <input
                type="number"
                className="border border-gray-300 rounded px-3 py-2 mb-4"
                value={userAnswer}
                onChange={(e) => setUserAnswer(e.target.value)}
            />
            <div>
                <button
                    className="bg-orange-500 text-white py-2 px-6 rounded-full font-semibold"
                    onClick={checkAnswer}
                >
                    উত্তর দিন
                </button>
            </div>
            {feedback && <p className="mt-4 text-lg">{feedback}</p>}
        </div>
    );
};

const ArtistStudio = () => {
    return (
        <div>
            <h3>আর্টিস্ট স্টুডিও</h3>
            <p>এখানে আপনি অনলাইনে ছবি আঁকতে পারেন।</p>
            {/* আঁকার জন্য আরও কোড যুক্ত করুন */}
        </div>
    );
};

const MusicMaker = () => {
    return (
        <div>
            <h3>সঙ্গীত নির্মাতা</h3>
            <p>এখানে আপনি সুর ও সঙ্গীত তৈরি করতে পারেন।</p>
            {/* সঙ্গীত তৈরির জন্য আরও কোড যুক্ত করুন */}
        </div>
    );
};

const WordPuzzle = () => {
    return (
        <div>
            <h3>শব্দ ধাঁধা</h3>
            <p>এখানে আপনি শব্দ ধাঁধা সমাধান করতে পারেন।</p>
            {/* শব্দ ধাঁধার জন্য আরও কোড যুক্ত করুন */}
        </div>
    );
};

const StoryCreator = () => {
    return (
        <div>
            <h3>গল্প সৃষ্টিকারী</h3>
            <p>এখানে আপনি গল্প লিখতে ও শেয়ার করতে পারেন।</p>
            {/* গল্প লেখার জন্য আরও কোড যুক্ত করুন */}
        </div>
    );
};

export default Apps;
