// ClassTypeChart.js
import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const ClassTypeChart = ({ filteredRegistrations }) => {
  const classTypeData = [
    { name: 'Offline', value: filteredRegistrations.filter((r) => r.preferred_class_type === 'OFFLINE').length },
    { name: 'Online', value: filteredRegistrations.filter((r) => r.preferred_class_type === 'ONLINE').length },
    { name: 'Hybrid', value: filteredRegistrations.filter((r) => r.preferred_class_type === 'HYBRID').length },
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  return (
    <div className="bg-white p-6 rounded-2xl shadow-lg">
      <h2 className="text-2xl font-bold mb-4 text-orange-600">Class Type Distribution</h2>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={classTypeData}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
          >
            {classTypeData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ClassTypeChart;
