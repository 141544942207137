import React, { useState } from 'react';
import { motion } from 'framer-motion';

function SuccessPage() {
    const [language, setLanguage] = useState('bn');

    const content = {
        en: {
            success: {
                title: "Registration Successful!",
                message: "Thank you for registering for the free class. We will contact you soon with further details."
            },
            languageToggle: 'বাংলা'
        },
        bn: {
            success: {
                title: "রেজিস্ট্রেশন সফল!",
                message: "ফ্রি ক্লাসের জন্য রেজিস্ট্রেশন করার জন্য ধন্যবাদ। আমরা শীঘ্রই আপনার সাথে যোগাযোগ করব।"
            },
            languageToggle: 'English'
        }
    };

    const toggleLanguage = () => setLanguage(language === 'en' ? 'bn' : 'en');

    return (
        <div className="container mx-auto px-4 py-8">
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="bg-white p-8 rounded-2xl shadow-2xl w-full max-w-3xl mx-auto space-y-6"
            >
                <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
                    <h1 className="text-4xl font-bold text-center text-green-600">{content[language].success.title}</h1>
                    <button
                        onClick={toggleLanguage}
                        className="px-4 py-2 bg-orange-500 text-white rounded-full hover:bg-orange-600 transition duration-300"
                    >
                        {content[language].languageToggle}
                    </button>
                </div>
                <p className="text-xl text-center text-gray-700">
                    {content[language].success.message}
                </p>
            </motion.div>
        </div>
    );
}

export default SuccessPage;
