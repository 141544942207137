// Filters.js
import React from 'react';
import { Calendar, Users, Phone } from 'lucide-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Filters = ({
  searchTerm,
  setSearchTerm,
  phoneSearchTerm,
  setPhoneSearchTerm,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  filterClassType,
  setFilterClassType,
  filterTrack,
  setFilterTrack,
}) => {
  return (
    <section className="bg-white rounded-2xl shadow-lg p-6">
      <h2 className="text-2xl font-semibold text-orange-600 mb-4">Filters</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-4">
        {/* Search by student or parent name */}
        <div className="flex items-center bg-orange-100 rounded-full px-4 py-2">
          <Users size={20} className="text-orange-500 mr-2" />
          <input
            type="text"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="bg-transparent focus:outline-none w-full"
          />
        </div>

        {/* Search by phone number */}
        <div className="flex items-center bg-orange-100 rounded-full px-4 py-2">
          <Phone size={20} className="text-orange-500 mr-2" />
          <input
            type="text"
            placeholder="Search by phone number..."
            value={phoneSearchTerm}
            onChange={(e) => setPhoneSearchTerm(e.target.value)}
            className="bg-transparent focus:outline-none w-full"
          />
        </div>

        {/* Start Date Filter */}
        <div className="flex items-center bg-orange-100 rounded-full px-4 py-2">
          <Calendar size={20} className="text-orange-500 mr-2" />
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="Start Date"
            className="bg-transparent focus:outline-none w-full"
          />
        </div>

        {/* End Date Filter */}
        <div className="flex items-center bg-orange-100 rounded-full px-4 py-2">
          <Calendar size={20} className="text-orange-500 mr-2" />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="End Date"
            className="bg-transparent focus:outline-none w-full"
          />
        </div>

        {/* Class Type Filter */}
        <select
          value={filterClassType}
          onChange={(e) => setFilterClassType(e.target.value)}
          className="bg-orange-100 rounded-full px-4 py-2 focus:outline-none"
        >
          <option value="">All Class Types</option>
          <option value="OFFLINE">Offline Class</option>
          <option value="ONLINE">Online Class</option>
          <option value="HYBRID">Hybrid Class</option>
        </select>

        {/* Track Filter */}
        <select
          value={filterTrack}
          onChange={(e) => setFilterTrack(e.target.value)}
          className="bg-orange-100 rounded-full px-4 py-2 focus:outline-none"
        >
          <option value="">All Tracks</option>
          <option value="Track 1 (6-8)">Track 1 (6-8)</option>
          <option value="Track 2 (9-12)">Track 2 (9-12)</option>
          <option value="Track 3 (13-16+)">Track 3 (13-16+)</option>
        </select>
      </div>
    </section>
  );
};

export default Filters;
