import React, { useState, useEffect } from 'react'; // Importing required hooks
import { useParams } from 'react-router-dom'; // Importing useParams for accessing route params
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import Papa from 'papaparse';

import { Copy, Check, Calendar, Clock } from 'lucide-react'; // Importing icons

const BlogPost = () => {
  const { slug } = useParams();
  const [blogPost, setBlogPost] = useState(null);
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(true);

  const sheetId = "1LCc14doDmZdMUdFFSK475KefRJ2gbcP52cenKE0ZWgE";
  const sheetName = "Sheet1";
  const url = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?tqx=out:csv&sheet=${sheetName}`;

  const createSlug = (title) => {
    return title
      .trim()
      .replace(/\s+/g, '-')
      .replace(/[^\u0000-\uFFFF]/g, '') // Only remove special characters, keep Unicode
      .toLowerCase();
  };

  const parseDate = (dateString) => {
    const parsedDate = new Date(dateString);
    return isNaN(parsedDate) ? 'Invalid Date' : parsedDate;
  };

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await axios.get(url);
        const csvData = response.data;

        Papa.parse(csvData, {
          header: true,
          complete: (result) => {
            const blogs = result.data.map((row) => ({
              title: row.Title,
              description: row.Descriptions,
              imageUrl: row["Image Link"],
              date: parseDate(row.Date),
              slug: createSlug(row.Title),
              readTime: Math.floor(Math.random() * 10) + 5, // Random read time between 5-15 minutes
              content: row.Content || '', // Assuming you have a 'Content' column
            }));

            const post = blogs.find((blog) => blog.slug === slug);

            if (post) {
              setBlogPost(post);
            }
            setLoading(false);
          },
          error: (error) => {
            console.error("Error parsing CSV data:", error);
            setLoading(false);
          },
        });
      } catch (error) {
        console.error("Error fetching blog data:", error);
        setLoading(false);
      }
    };

    fetchBlogData();
  }, [slug, url]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-orange-500"></div>
      </div>
    );
  }

  if (!blogPost) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-orange-400 to-orange-600">
        <div className="text-center text-white p-8 backdrop-blur-md bg-white/30 rounded-xl">
          <h1 className="text-5xl font-extrabold mb-4">Oops! Post Not Found</h1>
          <p className="text-xl">This blog post seems to have vanished into the digital ether.</p>
        </div>
      </div>
    );
  }

  const copyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  // Split the description into paragraphs
  const descriptionParagraphs = blogPost.description.split('\n\n');

  return (
    <HelmetProvider>
      <div className="min-h-screen bg-gradient-to-br from-orange-100 via-white to-orange-100 py-12 px-4 sm:px-6 lg:px-8">
        <Helmet>
          {/* Open Graph Meta Tags */}
          <meta property="og:title" content={blogPost.title} />
          <meta property="og:description" content={blogPost.description} />
          <meta property="og:image" content={blogPost.imageUrl} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="article" />

          {/* Twitter Meta Tags */}
          <meta name="twitter:title" content={blogPost.title} />
          <meta name="twitter:description" content={blogPost.description} />
          <meta name="twitter:image" content={blogPost.imageUrl} />
          <meta name="twitter:card" content="summary_large_image" />

          <title>{blogPost.title} | NextGen Programmer</title>
          <meta name="description" content={blogPost.description} />
        </Helmet>

        <div className="max-w-6xl mx-auto">
          <div className="bg-white shadow-2xl rounded-3xl overflow-hidden transform transition-all hover:scale-[1.02] duration-300">
            <div className="relative h-[500px]">
              <img
                src={blogPost.imageUrl}
                alt={blogPost.title}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>
              <div className="absolute bottom-0 left-0 right-0 p-8">
                <h1 className="text-5xl sm:text-6xl font-bold text-white mb-4 leading-tight">
                  {blogPost.title}
                </h1>
                <div className="flex items-center text-orange-300 space-x-4">
                  <div className="flex items-center">
                    <Calendar className="w-5 h-5 mr-2" />
                    <span>
                      {blogPost.date instanceof Date && !isNaN(blogPost.date)
                        ? blogPost.date.toLocaleDateString()
                        : 'Invalid Date'}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <Clock className="w-5 h-5 mr-2" />
                    <span>{blogPost.readTime} min read</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-8 sm:p-12">
              <div className="mb-12">
                {descriptionParagraphs.map((paragraph, index) => (
                  <p key={index} className="text-xl text-gray-700 mb-6 leading-relaxed font-serif">
                    {paragraph}
                  </p>
                ))}
              </div>
              <div className="prose prose-lg max-w-none prose-orange">
                <div dangerouslySetInnerHTML={{ __html: blogPost.content }} />
              </div>
            </div>
          </div>

          <div className="mt-8 flex justify-end">
            <button
              onClick={copyLink}
              className={`flex items-center space-x-2 ${
                copied ? 'bg-green-500' : 'bg-orange-500'
              } text-white px-6 py-3 rounded-full text-lg font-semibold transition-all duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-4 focus:ring-orange-300`}
            >
              {copied ? (
                <>
                  <Check size={24} />
                  <span>Copied!</span>
                </>
              ) : (
                <>
                  <Copy size={24} />
                  <span>Copy Link</span>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
};

export default BlogPost;
