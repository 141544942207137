import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Papa from 'papaparse';
import { Link } from 'react-router-dom';
import { Calendar, Clock, ChevronRight } from 'lucide-react';

const Blog = () => {
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(true);

  const sheetId = "1LCc14doDmZdMUdFFSK475KefRJ2gbcP52cenKE0ZWgE";
  const sheetName = "Sheet1";
  const url = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?tqx=out:csv&sheet=${sheetName}`;

  const createSlug = (title) => {
    return title
      .trim()
      .replace(/\s+/g, '-')
      .replace(/[^\u0000-\uFFFF]/g, '') // Only remove special characters, keep Unicode
      .toLowerCase();
  };

  const truncateDescription = (description, maxLength) => {
    if (description.length > maxLength) {
      return description.substring(0, maxLength) + '...';
    }
    return description;
  };

  const parseDate = (dateString) => {
    const parsedDate = new Date(dateString);
    return isNaN(parsedDate) ? 'Invalid Date' : parsedDate;
  };

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await axios.get(url);
        const csvData = response.data;

        Papa.parse(csvData, {
          header: true,
          complete: (result) => {
            const blogs = result.data
              .map((row) => ({
                title: row.Title,
                description: row.Descriptions,
                imageUrl: row["Image Link"],
                date: parseDate(row.Date),
                slug: createSlug(row.Title),
                readTime: Math.floor(Math.random() * 10) + 5, // Random read time between 5-15 minutes
              }))
              .sort((a, b) => b.date - a.date); // Sort by date, newest first

            setBlogData(blogs);
            setLoading(false);
          },
          error: (error) => {
            console.error("Error parsing CSV data:", error);
            setLoading(false);
          },
        });
      } catch (error) {
        console.error("Error fetching blog data:", error);
        setLoading(false);
      }
    };

    fetchBlogData();
  }, [url]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-orange-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#fdf5e9] py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-5xl font-extrabold mb-12 text-center text-[#e86a23] tracking-tight">
          Explore Our Latest Insights
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-10">
          {blogData.map((blog, index) => (
            <div
              key={index}
              className="bg-white rounded-2xl shadow-xl overflow-hidden flex flex-col"
            >
              {/* Thumbnail Image as clickable link */}
              <Link to={`/blog/${blog.slug}`} className="relative h-64 block">
                <img
                  src={blog.imageUrl}
                  alt={blog.title}
                  className="h-full w-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
                <div className="absolute bottom-4 left-4 right-4">
                  <h2 className="text-2xl font-bold mb-2 text-white leading-tight">
                    {blog.title}
                  </h2>
                  <div className="flex items-center text-orange-300 space-x-4">
                    <div className="flex items-center">
                      <Calendar className="w-4 h-4 mr-1" />
                      <span className="text-sm">
                        {blog.date instanceof Date && !isNaN(blog.date)
                          ? blog.date.toLocaleDateString()
                          : 'Invalid Date'}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <Clock className="w-4 h-4 mr-1" />
                      <span className="text-sm">{blog.readTime} min read</span>
                    </div>
                  </div>
                </div>
              </Link>
              {/* Description */}
              <div className="p-6 flex-grow">
                <p className="text-gray-600 mb-4 line-clamp-3">
                  {truncateDescription(blog.description, 150)}
                </p>
                <Link
                  to={`/blog/${blog.slug}`}
                  className="inline-flex items-center text-[#e86a23] font-semibold hover:text-orange-600 transition duration-300"
                >
                  Read Full Article
                  <ChevronRight className="ml-1 w-5 h-5" />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;
