import React from 'react';
import { motion } from 'framer-motion';
import { FaGraduationCap, FaLaptopCode, FaUsers, FaRocket } from 'react-icons/fa';

const About = () => {
    const features = [
        {
            icon: <FaGraduationCap className="text-4xl text-orange-500" />,
            title: 'উচ্চমানের শিক্ষা',
            description: 'আমরা আধুনিক প্রযুক্তি ব্যবহার করে উচ্চমানের শিক্ষা প্রদান করি।'
        },
        {
            icon: <FaLaptopCode className="text-4xl text-orange-500" />,
            title: 'হাতে-কলমে শিক্ষা',
            description: 'আমাদের কোর্সগুলি প্র্যাক্টিক্যাল প্রজেক্ট ভিত্তিক, যা শিক্ষার্থীদের বাস্তব অভিজ্ঞতা দেয়।'
        },
        {
            icon: <FaUsers className="text-4xl text-orange-500" />,
            title: 'সহযোগিতামূলক পরিবেশ',
            description: 'আমরা একটি সহযোগিতামূলক শিক্ষা পরিবেশ তৈরি করি যেখানে শিক্ষার্থীরা একে অপরের সাথে শিখতে পারে।'
        },
        {
            icon: <FaRocket className="text-4xl text-orange-500" />,
            title: 'ক্যারিয়ার সাপোর্ট',
            description: 'আমরা শিক্ষার্থীদের ক্যারিয়ার গঠনে সহায়তা করি এবং ইন্ডাস্ট্রি কানেকশন প্রদান করি।'
        }
    ];

    return (
        <div className="min-h-screen bg-gradient-to-br from-orange-100 to-white py-16">
            <div className="container mx-auto px-4">
                <motion.h1
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="text-5xl font-bold text-center text-orange-600 mb-12"
                >
                    আমাদের সম্পর্কে জানুন
                </motion.h1>

                <motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    className="text-xl text-gray-700 text-center mb-16 max-w-3xl mx-auto"
                >
                    আমরা একটি অভিনব প্রযুক্তি শিক্ষা প্লাটফর্ম যা বাংলাদেশের তরুণদের ডিজিটাল দক্ষতা উন্নয়নে নিবেদিত। আমাদের লক্ষ্য হলো প্রতিটি শিক্ষার্থীকে ভবিষ্যতের চ্যালেঞ্জ মোকাবেলায় প্রস্তুত করা।
                </motion.p>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                    {features.map((feature, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                            className="bg-white rounded-lg shadow-lg p-8 hover:shadow-xl transition-shadow duration-300"
                        >
                            <div className="flex items-center mb-4">
                                {feature.icon}
                                <h3 className="text-2xl font-semibold ml-4 text-orange-600">{feature.title}</h3>
                            </div>
                            <p className="text-gray-700">{feature.description}</p>
                        </motion.div>
                    ))}
                </div>

                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.6 }}
                    className="mt-16 text-center"
                >
                    <h2 className="text-3xl font-semibold mb-4 text-orange-600">আমাদের সাথে যোগ দিন</h2>
                    <p className="text-gray-700 mb-8 text-lg">
                        আপনি কি আমাদের মিশনে অংশ নিতে চান? আমাদের সাথে যোগ দিন এবং ভবিষ্যতের প্রযুক্তি নেতা হয়ে উঠুন।
                    </p>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="bg-orange-500 text-white py-3 px-8 rounded-full font-semibold text-lg hover:bg-orange-600 transition-colors duration-300"
                    >
                        আমাদের সাথে যোগাযোগ করুন
                    </motion.button>
                </motion.div>
            </div>
        </div>
    );
};

export default About;
