import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import { Home, Info, Code, Phone, Book, Rocket, LogIn, LogOut, Briefcase, Menu, X, MapPin, Mail, PhoneCall, ChevronRight, Globe, Facebook, Twitter, Instagram, Linkedin } from 'lucide-react';
// Import your components
import HomePage from './HomePage';
import About from './About';
import Programs from './Programs';
import Contact from './Contact';
import Apps from './Apps';
import RegistrationForm from './components/RegistrationForm';
import SuccessPage from './components/SuccessPage';
import AdminLogin from './components/AdminLogin';
import AdminDashboard from './components/AdminDashboard/AdminDashboard';
import CareerSection from './components/CareerSection';
import Blog from './components/Blog';
import BlogPost from './components/BlogPost';

// Utility function to create a slug from the title
const createSlug = (title) => {
  return title
    .trim() // Remove leading/trailing spaces
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/[^\u0000-\uFFFF]/g, '') // Only remove special characters, keep Unicode (like Bangla)
    .toLowerCase(); // Make it lowercase
};

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  if (!token) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

function App() {
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);
  const [language, setLanguage] = useState('en'); // Default language set to English
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  useEffect(() => {
    // Simulate an async check to verify login status
    const token = localStorage.getItem('token');
    setIsAdminLoggedIn(!!token);
    setTimeout(() => {
      setIsLoading(false); // Set loading to false once the initialization is done
    }, 1000); // Simulating loading time
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAdminLoggedIn(false);
  };

  const toggleLanguage = () => {
    setLanguage(language === 'bn' ? 'en' : 'bn');
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const menuItems = [
    { to: "/", icon: <Home size={20} />, text: language === 'bn' ? 'হোম' : 'Home' },
    { to: "/about", icon: <Info size={20} />, text: language === 'bn' ? 'আমাদের সম্পর্কে' : 'About Us' },
    { to: "/programs", icon: <Code size={20} />, text: language === 'bn' ? 'প্রোগ্রামসমূহ' : 'Programs' },
    { to: "/contact", icon: <Phone size={20} />, text: language === 'bn' ? 'যোগাযোগ' : 'Contact' },
    { to: "/register", icon: <Book size={20} />, text: language === 'bn' ? 'ফ্রি  ক্লাস বুক করুন' : 'Book Free Class' },
    { to: "/apps", icon: <Rocket size={20} />, text: language === 'bn' ? 'অ্যাপস' : 'Apps' },
    { to: "/blog", icon: <Rocket size={20} />, text: language === 'bn' ? 'ব্লগ' : 'Blog' },
    { to: "/careers", icon: <Briefcase size={20} />, text: language === 'bn' ? 'ক্যারিয়ার' : 'Careers' },
  ];

  if (isLoading) {
    // Show loader/spinner while the app is loading
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-orange-100 to-orange-200">
        <div className="relative flex justify-center items-center">
          <div className="w-16 h-16 border-4 border-orange-400 border-t-transparent border-dotted rounded-full animate-spin"></div>
          <span className="absolute font-bold text-orange-600 text-2xl">Loading...</span>
        </div>
        <p className="mt-4 text-orange-500 text-lg">Please wait while the app is loading.</p>
      </div>
    );
  }

  return (
    <Router>
      <div className="min-h-screen flex flex-col bg-gradient-to-br from-orange-50 to-orange-100">
        {/* Navigation */}
        <nav className="bg-white shadow-md p-4">
          <div className="container mx-auto flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={toggleSidebar} className="lg:hidden mr-4">
                <Menu size={24} />
              </button>
              <Link to="/">
                <img src="/images/nextgen-logo.png" alt="NextGen Programmer Logo" className="h-12"/>
              </Link>
            </div>
            <div className="hidden lg:flex items-center space-x-6">
              {menuItems.map((item, index) => (
                <Link key={index} to={item.to} className="text-gray-700 hover:text-orange-500 flex items-center">
                  {item.icon}
                  <span className="ml-2">{item.text}</span>
                </Link>
              ))}
            </div>
            <div className="flex items-center">
              <button
                onClick={toggleLanguage}
                className="mr-4 p-2 bg-orange-500 text-white rounded-full flex items-center"
              >
                <Globe size={20} className="mr-2" />
                <span>{language === 'en' ? 'বাংলা' : 'English'}</span>
              </button>
              {isAdminLoggedIn ? (
                <button onClick={handleLogout} className="flex items-center text-gray-700 hover:text-orange-500">
                  <LogOut size={20} className="mr-2" />
                  <span>{language === 'bn' ? 'লগআউট' : 'Logout'}</span>
                </button>
              ) : (
                <Link to="/login" className="flex items-center text-gray-700 hover:text-orange-500">
                  <LogIn size={20} className="mr-2" />
                  <span>{language === 'bn' ? 'লগইন' : 'Login'}</span>
                </Link>
              )}
            </div>
          </div>
        </nav>

        {/* Sidebar for mobile and tablet */}
        <div className={`lg:hidden fixed inset-y-0 left-0 transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} transition duration-300 ease-in-out z-30 w-64 bg-white shadow-lg`}>
          <div className="p-6">
            <div className="flex justify-between items-center mb-8">
              <h2 className="text-2xl font-bold text-orange-600">NextGen Programmer</h2>
              <button onClick={toggleSidebar} className="text-gray-500 hover:text-gray-800">
                <X size={24} />
              </button>
            </div>
            <ul className="space-y-4">
              {menuItems.map((item, index) => (
                <li key={index}>
                  <Link to={item.to} className="flex items-center text-gray-700 hover:text-orange-500" onClick={toggleSidebar}>
                    {item.icon}
                    <span className="ml-2">{item.text}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Main Content */}
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<HomePage language={language} />} />
            <Route path="/about" element={<About language={language} />} />
            <Route path="/programs" element={<Programs language={language} />} />
            <Route path="/contact" element={<Contact language={language} />} />
            <Route path="/apps" element={<Apps language={language} />} />
            <Route path="/register" element={<RegistrationForm language={language} />} />
            <Route path="/registration/success" element={<SuccessPage language={language} />} />
            <Route path="/login" element={<AdminLogin setIsAdminLoggedIn={setIsAdminLoggedIn} language={language} />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<BlogPost />} />
            <Route path="/careers" element={<CareerSection language={language} />} />
            <Route
              path="/admin/dashboard"
              element={
                <ProtectedRoute>
                  <AdminDashboard language={language} />
                </ProtectedRoute>
              }
            />
          </Routes>
        </main>

        {/* Footer */}
        <footer className="bg-gradient-to-r from-gray-800 to-gray-900 text-white py-12">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="space-y-4">
                <h3 className="text-2xl font-bold mb-4 text-orange-400">NextGen Programmer</h3>
                <p className="flex items-start">
                  <MapPin className="mr-2 mt-1 flex-shrink-0 text-orange-400" size={20} />
                  <span>
                    {language === 'bn'
                      ? 'ফেনী সুপার মার্কেট, ৪র্থ তলা, (প্রাক্তন সুরত মহল সিনেমা হল ভবন), দোয়েল চত্বর, ট্রাংক রোড, ফেনী।'
                      : 'Feni Super Market, 4th floor, (Former Surot Mohal Cinema Hall Bhavan), Doyal Chattar, Trunk Road, Feni.'}
                  </span>
                </p>
                <p className="flex items-center">
                  <PhoneCall className="mr-2 text-orange-400" size={20} />
                  <span>88 01717 063191 | +88 01302064001</span>
                </p>
                <p className="flex items-center">
                  <Mail className="mr-2 text-orange-400" size={20} />
                  <span>info@nextgenprogrammer.com</span>
                </p>
              </div>
              <div>
                <h3 className="text-2xl font-bold mb-4 text-orange-400">{language === 'bn' ? 'দ্রুত লিঙ্ক' : 'Quick Links'}</h3>
                <ul className="space-y-2">
                  {menuItems.map((item, index) => (
                    <li key={index}>
                      <Link to={item.to} className="hover:text-orange-400 transition duration-300 flex items-center">
                        <ChevronRight size={16} className="mr-2" />
                        {item.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className="text-2xl font-bold mb-4 text-orange-400">{language === 'bn' ? 'আমাদের সাথে যুক্ত থাকুন' : 'Connect With Us'}</h3>
                <div className="flex space-x-4 mb-4">
                  <a href="https://www.facebook.com/NextGenProgramme/" className="hover:text-orange-400 transition duration-300"><Facebook size={24} /></a>
                  <a href="#" className="hover:text-orange-400 transition duration-300"><Twitter size={24} /></a>
                  <a href="#" className="hover:text-orange-400 transition duration-300"><Instagram size={24} /></a>
                  <a href="https://www.linkedin.com/company/nextgenprogrammer" className="hover:text-orange-400 transition duration-300"><Linkedin size={24} /></a>
                </div>
                <div className="bg-white p-2 inline-block rounded-lg">
                  <img src="/images/fb-qr-code.png" alt="Facebook QR Code" className="w-32 h-32" />
                </div>
              </div>
            </div>
            <div className="mt-8 pt-8 border-t border-gray-700 text-center">
              <p>© 2024 NextGen Programmer. {language === 'bn' ? 'সর্বস্বত্ব সংরক্ষিত।' : 'All rights reserved.'}</p>
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
