import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const languages = [
    { name: 'Spanish', flag: '🇪🇸' },
    { name: 'French', flag: '🇫🇷' },
    { name: 'Japanese', flag: '🇯🇵' },
    { name: 'German', flag: '🇩🇪' },
    { name: 'Italian', flag: '🇮🇹' },
];

const words = {
    Spanish: [
        { word: 'Hola', emoji: '👋', translation: 'Hello' },
        { word: 'Gato', emoji: '🐱', translation: 'Cat' },
        { word: 'Sol', emoji: '☀️', translation: 'Sun' },
        { word: 'Árbol', emoji: '🌳', translation: 'Tree' },
        { word: 'Feliz', emoji: '😊', translation: 'Happy' },
    ],
    French: [
        { word: 'Bonjour', emoji: '👋', translation: 'Hello' },
        { word: 'Chat', emoji: '🐱', translation: 'Cat' },
        { word: 'Soleil', emoji: '☀️', translation: 'Sun' },
        { word: 'Arbre', emoji: '🌳', translation: 'Tree' },
        { word: 'Heureux', emoji: '😊', translation: 'Happy' },
    ],
    Japanese: [
        { word: 'こんにちは', emoji: '👋', translation: 'Hello' },
        { word: 'ねこ', emoji: '🐱', translation: 'Cat' },
        { word: '太陽', emoji: '☀️', translation: 'Sun' },
        { word: '木', emoji: '🌳', translation: 'Tree' },
        { word: '幸せ', emoji: '😊', translation: 'Happy' },
    ],
    German: [
        { word: 'Hallo', emoji: '👋', translation: 'Hello' },
        { word: 'Katze', emoji: '🐱', translation: 'Cat' },
        { word: 'Sonne', emoji: '☀️', translation: 'Sun' },
        { word: 'Baum', emoji: '🌳', translation: 'Tree' },
        { word: 'Glücklich', emoji: '😊', translation: 'Happy' },
    ],
    Italian: [
        { word: 'Ciao', emoji: '👋', translation: 'Hello' },
        { word: 'Gatto', emoji: '🐱', translation: 'Cat' },
        { word: 'Sole', emoji: '☀️', translation: 'Sun' },
        { word: 'Albero', emoji: '🌳', translation: 'Tree' },
        { word: 'Felice', emoji: '😊', translation: 'Happy' },
    ],
};

const EmojiQuest = () => {
    const [currentLanguage, setCurrentLanguage] = useState(null);
    const [currentWord, setCurrentWord] = useState(null);
    const [score, setScore] = useState(0);
    const [lives, setLives] = useState(3);
    const [gameOver, setGameOver] = useState(false);
    const [streak, setStreak] = useState(0);
    const [highScore, setHighScore] = useState(0);

    useEffect(() => {
        if (score > highScore) {
            setHighScore(score);
        }
    }, [score, highScore]);

    const startGame = () => {
        const newLanguage = languages[Math.floor(Math.random() * languages.length)];
        setCurrentLanguage(newLanguage);
        setScore(0);
        setLives(3);
        setGameOver(false);
        setStreak(0);
        setNewWord(newLanguage);
    };

    const setNewWord = (language) => {
        const languageWords = words[language.name];
        setCurrentWord(languageWords[Math.floor(Math.random() * languageWords.length)]);
    };

    useEffect(() => {
        if (currentLanguage) {
            setNewWord(currentLanguage);
        }
    }, [currentLanguage]);

    const handleAnswer = (isCorrect) => {
        if (isCorrect) {
            setScore(score + 10 + streak * 2);
            setStreak(streak + 1);
            if (streak > 0 && streak % 5 === 0) {
                setLives(lives + 1);
            }
        } else {
            setLives(lives - 1);
            setStreak(0);
            if (lives === 1) {
                setGameOver(true);
                return;
            }
        }

        if (currentLanguage) {
            setNewWord(currentLanguage);
        }
    };

    return (
        <div style={{
            fontFamily: '"Comic Sans MS", cursive',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            background: 'linear-gradient(45deg, #FF9A8B, #FF6B6B, #4ECDC4, #45B7D1)',
            backgroundSize: '400% 400%',
            animation: 'gradient 15s ease infinite',
            color: '#FFFFFF',
            padding: '20px',
        }}>
            <h1 style={{ fontSize: '2.5em', marginBottom: '20px', textShadow: '2px 2px 4px #000000' }}>
                Emoji Quest: The Language Adventure 🌍🗣️
            </h1>

            {!currentLanguage && !gameOver && (
                <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={startGame}
                    style={{
                        fontSize: '1.2em',
                        padding: '15px 30px',
                        background: '#FFD700',
                        border: 'none',
                        borderRadius: '30px',
                        cursor: 'pointer',
                        boxShadow: '0 5px 15px rgba(0, 0, 0, 0.2)',
                        color: '#000000',
                        fontWeight: 'bold',
                    }}
                >
                    Start Adventure! 🚀
                </motion.button>
            )}

            {currentLanguage && currentWord && !gameOver && (
                <>
                    <div style={{
                        fontSize: '1.2em',
                        marginBottom: '20px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        maxWidth: '400px',
                    }}>
                        <span>Score: {score}</span>
                        <span>Lives: {'❤️'.repeat(lives)}</span>
                        <span>Streak: 🔥 {streak}</span>
                    </div>

                    <div style={{
                        fontSize: '2em',
                        marginBottom: '20px',
                        textAlign: 'center',
                    }}>
                        <div>{currentLanguage.flag} {currentLanguage.name}</div>
                        <div style={{ fontSize: '3em', marginTop: '20px' }}>{currentWord.emoji}</div>
                        <div style={{ fontSize: '1.5em', marginTop: '10px' }}>{currentWord.word}</div>
                    </div>

                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        maxWidth: '500px',
                    }}>
                        {words[currentLanguage.name].map((word, index) => (
                            <motion.button
                                key={index}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={() => handleAnswer(word.translation === currentWord.translation)}
                                style={{
                                    fontSize: '1em',
                                    padding: '10px 20px',
                                    margin: '10px',
                                    background: '#FFFFFF',
                                    border: 'none',
                                    borderRadius: '20px',
                                    cursor: 'pointer',
                                    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
                                    color: '#000000',
                                }}
                            >
                                {word.translation}
                            </motion.button>
                        ))}
                    </div>
                </>
            )}

            {gameOver && (
                <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    style={{
                        background: 'rgba(255, 255, 255, 0.9)',
                        padding: '20px',
                        borderRadius: '10px',
                        textAlign: 'center',
                        boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)',
                        color: '#000000',
                    }}
                >
                    <h2 style={{ marginBottom: '10px' }}>Adventure Completed! 🎉</h2>
                    <p>Your language quest score: {score}</p>
                    <p>High Score: {highScore}</p>
                    <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={startGame}
                        style={{
                            fontSize: '1em',
                            padding: '10px 20px',
                            background: '#4ECDC4',
                            border: 'none',
                            borderRadius: '20px',
                            cursor: 'pointer',
                            marginTop: '10px',
                            color: '#FFFFFF',
                            fontWeight: 'bold',
                        }}
                    >
                        Embark on a New Quest! 🗺️
                    </motion.button>
                </motion.div>
            )}
        </div>
    );
};

export default EmojiQuest;